import React from 'react';
import { Icons } from './resolver';

const Icon = ({
  raw,
  name,
  className,
  iconClassName,
  color,
  onClick,
  disabled,
  size,
  rest,
}) => {
  const IconSVG = Icons[name];
  return raw ? (
    <IconSVG className={className} color={color} size={size} />
  ) : (
    <button
      onClick={onClick}
      className={className}
      disabled={disabled}
      {...rest}
    >
      <IconSVG className={iconClassName} color={color} size={size} />
    </button>
  );
};

export { Icon };
