export const FOCUS_AREA_LINKS = {
  "Sport Performance":
    "https://www.weriise.co/blogs/sport-performance-riise-supplement",
  Energy: "https://www.weriise.co/blogs/energy-riise-supplement",
  Focus: "https://www.weriise.co/blogs/focus-riise-supplement",
  Mood: "https://www.weriise.co/blogs/mood-riise-supplement",
  Sleep: "https://www.weriise.co/blogs/sleep-riise-supplement",
  Stress: "https://www.weriise.co/blogs/stress-riise-supplement",
  Digestion: "https://www.weriise.co/blogs/digestion-riise-supplement",
  "Immune System":
    "https://www.weriise.co/blogs/immune-system-riise-supplement",
  Skin: "https://www.weriise.co/blogs/skin-riise-supplement",
  Hair: "https://www.weriise.co/blogs/hair-riise-supplement",
  Nails: "https://www.weriise.co/blogs/nails-riise-supplement",
  Heart: "https://www.weriise.co/blogs/heart-riise-supplement",
  "Muscle Pain": "https://www.weriise.co/blogs/muscle-pain-riise-supplement",
  Eyes: "https://www.weriise.co/blogs/eyes-riise-supplement",
  "Bones and Joints":
    "https://www.weriise.co/blogs/bones-and-joints-riise-supplement",
  "Sportliche Leistung":
    "https://www.weriise.co/blogs/sport-performance-riise-supplement",
  Energie: "https://www.weriise.co/blogs/energy-riise-supplement",
  Schwerpunkt: "https://www.weriise.co/blogs/focus-riise-supplement",
  Stimmung: "https://www.weriise.co/blogs/mood-riise-supplement",
  Schlaf: "https://www.weriise.co/blogs/sleep-riise-supplement",
  Verdauung: "https://www.weriise.co/blogs/digestion-riise-supplement",
  Immunsystem: "https://www.weriise.co/blogs/immune-system-riise-supplement",
  Haut: "https://www.weriise.co/blogs/skin-riise-supplement",
  Haare: "https://www.weriise.co/blogs/hair-riise-supplement",
  Nägel: "https://www.weriise.co/blogs/nails-riise-supplement",
  Herz: "https://www.weriise.co/blogs/heart-riise-supplement",
  Muskelschmerzen: "https://www.weriise.co/blogs/muscle-pain-riise-supplement",
  Augen: "https://www.weriise.co/blogs/eyes-riise-supplement",
  "Knochen und Gelenke":
    "https://www.weriise.co/blogs/bones-and-joints-riise-supplement",
};
