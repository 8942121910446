import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import authReducer from '../features/auth/authSlice';
import langReducer from '../features/langSlice';
import contentsReducer from '../features/contentsSlice'

import { userReducer } from "./api/useDataManage/useReducer";
import { cartReducer } from "./api/cart/cartReducer";
import { subReducers } from "./api/useDataManage/subscriptionApis";

export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        user: userReducer,
        cart: cartReducer,
        subs: subReducers,
        auth: authReducer,
        lang: langReducer,
        contents: contentsReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(apiSlice.middleware),
    devTools: true
})
