import { createSlice } from "@reduxjs/toolkit"
import {FreeShippingOrders} from "../Constants";

const contentsSlice = createSlice({
    name: 'contents',
    initialState: {
        freeShippingOrders: '',
        cookieBannerMessage: '',
        takeTheTest: '',
        slug: ''
    },
    reducers: {
        setFreeShippingOrders: (state, action) => {
            const { content } = action.payload
            state.freeShippingOrders = content
        },
        setCookieBannerMessage: (state, action) => {
            const { message } = action.payload
            state.cookieBannerMessage = message
        },
        setTakeTheTest: (state, action) => {
            const { title } = action.payload
            state.takeTheTest = title;
        },
        setSlug: (state, action) => {
            const { slug } = action.payload

            state.slug = slug;
        },
    },
})

export const { setFreeShippingOrders, setCookieBannerMessage, setTakeTheTest , setSlug} = contentsSlice.actions

export default contentsSlice.reducer;

export const selectFreeShippingOrders = (state) => state.contents.freeShippingOrders;
export const selectCookieBannerMessage = (state) => state.contents.cookieBannerMessage;
export const selectTakeTheTestTitle = (state) => state.contents.takeTheTest;
export const selectSlug = (state) => state.contents.slug;