import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {setLanguage} from "../../features/langSlice";

const EmptyHeader = () => {
    const [selected, setSelected] = useState(localStorage.getItem('lang') || 'en');
    const dispatch = useDispatch();

    useEffect(() => {

        dispatch(setLanguage({lng:selected}))

    }, [selected]);

}

export default EmptyHeader;