import React from "react";
import { Icon } from "../../elements/icon";
import { useTranslation } from "react-i18next";
import OneMonth from "../../../assets/product/1m-desktop.png";
import ThreeMonth from "../../../assets/product/3m-desktop.png";
import SixMonth from "../../../assets/product/6m-desktop.png";

import {
  Box1mLine1,
  Box1mLine2,
  Box1mLine3,
  Box1mLine4,
  Box3mLine1,
  Box6mLine1,
  Box6mLine2,
  Box6mLine3,
  Box6mLine4,
} from "../../../Constants";

const SubscriptionCard = ({
  item,
  onSelectPlan,
  subscribtion,
  id,
  loading,
  showSelectBtn = true,
  currentPlan,
}) => {
  const { t } = useTranslation();
  return (
    <div className="t-relative !t-w-full t-bg-white !t-max-w-[300px] sm:!t-w-[200px] t-flex t-flex-col t-px-2 md:t-px-4 t-py-4 md:t-py-6 t-border t-border-gry-200 t-rounded-lg t-gap-2">
      <img
        src={
          item.duration === "6 months"
            ? SixMonth
            : item.duration === "30 days"
            ? OneMonth
            : ThreeMonth
        }
        alt=""
        className={`t-w-[60%] t-h-auto t-mx-auto`}
      />
      <p className="t-text-xl sm:t-text-lg !t-font-[600] !t-leading-normal t-mb-0 t-max-w-[80%] sm:t-max-w-[100%] sm:t-text-center">
        {item.title === "30 days"
          ? "Try once"
          : item.title === "3 months"
          ? "Monthly plan"
          : item.title === "1 month"
          ? "Monthly plan"
          : "3 months plan"}
      </p>
      {/* temporarily switch off buy boxes */}
      {showSelectBtn && (
        <button
          className="!t-text-sm !t-text-capitalize btn-custom-quaternary !t-py-2 !t-bg-secondary disabled:!t-bg-quaternary hover:!t-text-white"
          onClick={() => onSelectPlan(item, id)}
          disabled={loading || subscribtion?.subId === item?.id}
        >
          {subscribtion?.subId === item?.id ? "Selected" : "Select"}
        </button>
      )}
      {/* end */}
      <div className="t-flex t-flex-col !t-gap-1">
        <div className="t-flex t-items-start t-gap-2">
          <Icon name="tick" size="16" className="t-mt-1" />
          <p className="t-text-sm t-mb-0">
            {item.duration === "6 months"
              ? t(Box6mLine1)
              : item.duration === "3 months"
              ? t(Box3mLine1)
              : item.duration === "30 days"
              ? "30 individual servings"
              : t(Box1mLine1)}
          </p>
        </div>
        <div className="t-flex t-items-start t-gap-2">
          <Icon name="tick" size="16" className="t-mt-1" />
          <p className="t-text-sm t-mb-0">
            {item.duration === "6 monhts"
              ? t(Box6mLine2)
              : item.duration === "30 days"
              ? "No subscription"
              : t(Box1mLine2)}
          </p>
        </div>
        {item.duration !== "30 days" && (
          <div className="t-flex t-items-start t-gap-2">
            <Icon name="tick" size="16" className="t-mt-1" />
            <p className="t-text-sm t-mb-0">
              {item.duration === "6 months" ? t(Box6mLine3) : t(Box1mLine3)}
            </p>
          </div>
        )}
        {item.duration !== "30 days" && (
          <div className="t-flex t-items-start t-gap-2">
            <Icon name="tick" size="16" className="t-mt-1" />
            <p className="t-text-sm t-mb-0">
              {item.duration === "6 months" ? t(Box6mLine4) : t(Box1mLine4)}
            </p>
          </div>
        )}
      </div>
      <p className="t-absolute t-top-2 t-right-2 t-rounded-full t-bg-secondary t-text-white t-px-2 t-text-[10px]">
        {parseInt(item.duration) === parseInt(currentPlan.duration)
          ? "Current Plan"
          : null}
      </p>
    </div>
  );
};

export default SubscriptionCard;
