import moment from "moment/moment";

class Client
{
    constructor() {
        this.name = '';
        this.email = '';
        this.gender = '';
        this.dob = '';
        this.day = '';
        this.month = '';
        this.year = '';
        this.height = '';
        this.weight = '';
        this.country = '';
        this.data = [];
    };

    
    init = (values) => {

        Object.entries(values).forEach(([key, value]) => {
            Object.keys(value).map((key) => {
                
                switch(key) {
                    case 'name': this.name = value[key]['value']; break;
                    case 'email': this.email = value[key]['value']; break;
                    case 'gender': this.gender = value[key]['value']; break;
                    case 'day': this.day = value[key]['value']; break;
                    case 'month': this.month = value[key]['value']; break;
                    case 'year': this.year = value[key]['value']; break;
                    case 'height': this.height = value[key]['value']; break;
                    case 'weight': this.weight = value[key]['value']; break;
                    case 'country': this.country = value[key]['value']; break;
                    default: 
                        if(value[key]['value'] !== false)
                            this.data.push({[key]: value[key]['answerCode']})
                        break;
                }

            });
            
        });
    };

    get = () => {

        this.data.splice(0, 0, {country: this.country})
        this.data.splice(0, 0, {weight: this.weight})
        this.data.splice(0, 0, {height: this.height})
        this.data.splice(0, 0, {gender: this.gender})
        this.data.splice(0, 0, {dob: moment(`${this.year}-${this.month}-${this.day}`).format("YYYY-MM-DD")})
        this.data.splice(0, 0, {email: this.email})
        this.data.splice(0, 0, {name: this.name})

        return this.data;
    };
}

export default Client;