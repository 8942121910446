import React, { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

import AppLayout from '../Layout/Layout';
import EmptyLayout from '../Layout/EmptyLayout';
import { routes, quizRoutes } from './Routes';
import OrderComplete from '../Components/Checkout/OrderComplete';

const PublicRoutes = () => {
  return (
    <>
      <Routes>
        {routes.map(({ path, Component }, i) => (
          <Fragment key={i}>
            <Route element={<AppLayout />} key={i}>
              <Route path={path} element={Component} />
            </Route>
          </Fragment>
        ))}
        {quizRoutes.map(({ path, Component }, i) => (
          <Fragment key={i + 100}>
            <Route element={<EmptyLayout />} key={i + 100}>
              <Route path={path} element={Component} />
            </Route>
            <Route path={`/cart/ordercomplete`} element={<OrderComplete />} />
          </Fragment>
        ))}
      </Routes>
    </>
  );
};

export default PublicRoutes;
