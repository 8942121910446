import { apiSlice } from "../app/api/apiSlice";

export const ContactApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        sendInquiry: builder.mutation({
            query: payload => ({
                url: 'inquiry',
                method: 'POST',
                body: { ...payload }
            })
        }),
    })
})

export const {
    useSendInquiryMutation
} = ContactApiSlice