import React, { useState, useEffect } from 'react';

import { useGetBlogsQuery } from '../../features/blogApiSlice';
import { useSelector } from 'react-redux';
import { selectCurrentLanguage } from '../../features/langSlice';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { OurBlogs } from '../../Constants/index';

const Blogs = () => {
  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState();
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const {
    data: fetchBlogs,
    isSuccess,
    refetch,
  } = useGetBlogsQuery({ lang: lang, page: 1, rows: 100, category: 'blog' });

  useEffect(() => {
    setLang(selectedLang === 'us' ? 'en' : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    if (isSuccess) {
      setData(fetchBlogs.data);
    }
  }, [fetchBlogs, isSuccess]);

  const digitFormat = (digit) => {
    if (digit > 9) return digit;
    else return ('0' + digit).slice(-2);
  };

  return (
    <>
      <Helmet>
        <title>Riise Blogs</title>
        <meta name='description' content='Riise Blogs' />
      </Helmet>
      <section className='t-flex t-flex-col'>
        <div className='t-pt-6'>
          <div className='container '>
            <nav aria-label='Page breadcrumb'>
              <h2 className='title2 !t-text-[24px]'>{t(OurBlogs)}</h2>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item active' aria-current='page'>
                  <Link to={'/'} className='breadcrumb-item  !t-text-gray-500'>
                    Home
                  </Link>
                </li>
                <li className='breadcrumb-item  !t-text-gray-500'>
                  {t(OurBlogs)}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className='services blogs section-padding'>
        <div className='container'>
          <div className='row gy-4'>
            {data.map((blog, index) => (
              <div className='col-xl-4 col-md-6' key={index}>
                <div className='services-item'>
                  <span className='number'>{digitFormat(index + 1)}</span>
                  <a href={`blogs/${blog.slug}`}>
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}${blog.picture}`}
                      alt=''
                      className='img-fluid'
                    />
                  </a>
                  <div className='services-meta'>
                    <a href={`blogs/${blog.slug}`}>
                      <h4 className='col-xl-8 ps-0'>{blog.title}</h4>
                    </a>
                    <a
                      href={`blogs/${blog.slug}`}
                      className='full-paragraph-link col-xl-8 px-0'
                    >
                      {blog.contents}{' '}
                      <span className='read-more-text'>... Read more</span>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/*<div className="text-center"><a href="#" className="btn btn-custom-secondary fw-normal px-5">Load More</a></div>*/}
        </div>
      </section>
    </>
  );
};

export default Blogs;
