import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TopNavBar from './TopNavBar';
import {
  useCompareQuizWebMutation,
  useUpdateQuizVerWebMutation,
} from '../../features/customerApiSlice';
import { selectCurrentLanguage } from '../../features/langSlice';
import {
  IncludesIngredients,
  Nutrient,
  PersonalizedFormula,
  Quantity,
  YourFocusAreas,
} from '../../Constants';
import { useNavigate } from 'react-router-dom';
import { useGetAssetsQuery } from '../../features/quizApiSlice';
import FocusAreaCard from './components/FocusAreaCard';
import { Accordion } from '../elements/Accordion';

function QuizComparison() {
  const selectedLang = useSelector(selectCurrentLanguage);
  const { t } = useTranslation();
  const [lang, setLang] = useState();
  const [compareQuizWeb] = useCompareQuizWebMutation();
  const [updateQuizVerWeb] = useUpdateQuizVerWebMutation();

  const [ingredients, setIngredients] = useState([]);
  const [focusArea, setFocusArea] = useState([]);

  const [ingredients2, setIngredients2] = useState([]);
  const [focusArea2, setFocusArea2] = useState([]);

  const [currentQuizId, setCurrentQuizId] = useState(0);
  const [newQuizId, setNewQuizId] = useState(0);
  const history = useNavigate();

  useEffect(() => {
    const lng = localStorage.getItem('lang');

    setLang(lng === 'us' ? 'en' : lng);
  }, []);

  useEffect(() => {
    setLang(selectedLang === 'us' ? 'en' : selectedLang);

    const fn = async () => {
      if (lang) {
        const reqData = { lang };
        try {
          const res = await compareQuizWeb(reqData).unwrap();

          setCurrentQuizId(res.quiz_id);
          setIngredients(res.ingredients);
          setFocusArea(res.focusArea);

          setNewQuizId(res.new_quiz_id);
          setIngredients2(res.ingredients2);
          setFocusArea2(res.focusArea2);
        } catch (e) {
          console.log(e.message);
        }
      }
    };

    fn();
  }, [selectedLang, lang, compareQuizWeb]);

  const onSelectVersion = async (e, version) => {
    e.preventDefault();

    let ver = '';
    if (version === 'current') {
    } else if (version === 'new') {
      ver = 'new';
    }

    const reqData = { ver: ver, new_quiz_id: newQuizId };
    try {
      const res = await updateQuizVerWeb(reqData).unwrap();

      if (res.subsValid) {
        history(`/dashboard/comparison/message`);
      } else {
        history(`/product-suggestion`, {
          state: { quiz_id: ver === 'new' ? newQuizId : currentQuizId },
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const { data: focusAreaImages } = useGetAssetsQuery('focusArea');
  const { data: focusAreaIcons } = useGetAssetsQuery('focusAreaIcon');

  return (
    <Fragment>
      <div>
        <main>
          <section className='t-pt-6'>
            <div className='container'>
              <TopNavBar title={'Comparison'} />
            </div>
          </section>
          <section className='blog-details section-padding'>
            <div className='container'>
              <div className='row gx-sm-5 gy-4'>
                <div className='col-xl-12 col-lg-12 user-inner'>
                  <div className='blog-detail-inner'>
                    <h3 className='t-mb-8'>
                      Which version do you want to keep for your next order?
                    </h3>
                    <div className='product-suggestion-section section-padding t-flex t-flex-col t-gap-8 md:t-flex-row'>
                      <div className='col-12 col-md-6 t-flex t-flex-col t-gap-8'>
                        <div>
                          <button
                            type='button'
                            className='btn btn-custom-quaternary float-end'
                            onClick={(e) => onSelectVersion(e, 'current')}
                          >
                            Select
                          </button>
                          <h2>Current Version</h2>
                        </div>

                        <div className='t-flex t-flex-col t-gap-2'>
                          <h4 className='t-font-bold'>{t(YourFocusAreas)}</h4>
                          <div className='t-flex t-flex-wrap t-gap-2'>
                            {focusArea &&
                              focusArea.map((item) => {
                                const focusImage = focusAreaImages?.data?.find(
                                  (i) => i.name === item.answer
                                );
                                const focusIcon = focusAreaIcons?.data?.find(
                                  (i) => i.name === item.answer
                                );
                                return (
                                  <FocusAreaCard
                                    icon={focusIcon?.url}
                                    bg={focusImage?.url}
                                    name={item.answer}
                                    key={'focus-area-' + item.answer}
                                  />
                                );
                              })}
                          </div>
                        </div>
                        <div>
                          <h2>{t(PersonalizedFormula)}</h2>
                          <h5>
                            {t(IncludesIngredients).replace(
                              '{0}',
                              ingredients.length
                            )}
                          </h5>
                          <div className='table-responsive'>
                            <table
                              className='table accordion'
                              id='accordionExample'
                            >
                              <thead>
                                <tr>
                                  <td className='t-text-xs md:t-text-sm t-w-[55%] 2xl:t-w-[65%]'>
                                    {t(Nutrient)}
                                  </td>
                                  <td className='t-text-xs md:t-text-sm'>
                                    {t(Quantity)}
                                  </td>
                                  <td className='t-text-xs md:t-text-sm'>
                                    % NRV
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {ingredients &&
                                  ingredients.map((item, i) => (
                                    <tr key={'ingredient-' + i}>
                                      <td>
                                        <Accordion heading={item.ingredient}>
                                          <p className='t-text-sm'>
                                            {item.description}
                                          </p>
                                        </Accordion>
                                      </td>
                                      <td className='t-text-xs md:t-text-sm '>
                                        {item.daily_dose} mg
                                      </td>
                                      <td className='t-text-xs md:t-text-sm '>
                                        {item.per_eu_nrv === 0
                                          ? '*'
                                          : `${item.per_eu_nrv}%`}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-md-6 t-flex t-flex-col t-gap-8'>
                        <div>
                          <button
                            type='button'
                            className='btn btn-custom-quaternary float-end'
                            onClick={(e) => onSelectVersion(e, 'new')}
                          >
                            Select
                          </button>
                          <h2>New Version</h2>
                        </div>

                        <div className='t-flex t-flex-col t-gap-2'>
                          <h4 className='t-font-bold'>{t(YourFocusAreas)}</h4>
                          <div className='t-flex t-flex-wrap t-gap-2'>
                            {focusArea2 &&
                              focusArea2.map((item) => {
                                const focusImage = focusAreaImages?.data?.find(
                                  (i) => i.name === item.answer
                                );
                                const focusIcon = focusAreaIcons?.data?.find(
                                  (i) => i.name === item.answer
                                );
                                return (
                                  <FocusAreaCard
                                    icon={focusIcon?.url}
                                    bg={focusImage?.url}
                                    name={item.answer}
                                    key={'focus-area-' + item.answer}
                                  />
                                );
                              })}
                          </div>
                        </div>
                        <div>
                          <h2>{t(PersonalizedFormula)}</h2>
                          <h5>
                            {t(IncludesIngredients).replace(
                              '{0}',
                              ingredients2.length
                            )}
                          </h5>
                          <div className='table-responsive'>
                            <table
                              className='table accordion'
                              id='accordionExample'
                            >
                              <thead>
                                <tr>
                                  <td className='t-text-xs md:t-text-sm t-w-[55%] 2xl:t-w-[65%]'>
                                    {t(Nutrient)}
                                  </td>
                                  <td className='t-text-xs md:t-text-sm'>
                                    {t(Quantity)}
                                  </td>
                                  <td className='t-text-xs md:t-text-sm'>
                                    % NRV
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {ingredients2 &&
                                  ingredients2.map((item, i) => (
                                    <tr key={'ingredient-' + i}>
                                      <td>
                                        <Accordion heading={item.ingredient}>
                                          <p className='t-text-sm'>
                                            {item.description}
                                          </p>
                                        </Accordion>
                                      </td>
                                      <td className='t-text-xs md:t-text-sm '>
                                        {item.daily_dose} mg
                                      </td>
                                      <td className='t-text-xs md:t-text-sm '>
                                        {item.per_eu_nrv === 0
                                          ? '*'
                                          : `${item.per_eu_nrv}%`}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Fragment>
  );
}

export default QuizComparison;
