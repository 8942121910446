import React, {useState, useEffect} from "react";

import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../features/langSlice";
import { useGetTestimonialsQuery } from "../../../features/contentsApiSlice";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
    const selectedLang = useSelector(selectCurrentLanguage)

    const [data, setData] = useState([])
    const [lang, setLang] = useState ('en')
    const [pictures, setPictures] = useState([])
    const [skip, setSkip] = useState(false);
    
    const { data: fetchContents, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetTestimonialsQuery(lang);

    useEffect(() => {

        const fn = () => {
            if(isSuccess) {
    
                setData(fetchContents.data)

            }
        }

        fn();
    
    },[fetchContents]);
    
    useEffect(() => {

        if(lang !== selectedLang) {
            setLang(selectedLang==='us'?'en':selectedLang)

            refetch()
        }
    },[lang, selectedLang]);



     
  var settings = {

    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    arrows: false,
    centerMode: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 800,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 0,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ]
};


    return(
        <>
        <Slider  {...settings} >
            {data.map((row, index) => (
        
            <div key={index}>
                <div className="testimonials-item !t-mb-0" style={{margin: 10}}>
                    <div className="testimonials-body">
                        <div className="text-center">
                            <h3 className="!t-mb-2 !t-text-[18px]">{row.name}</h3>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: row.remarks }}></p>
                    </div>
                </div>
            </div>
            ))}
        </Slider>
        </>
    );
}

export default Testimonials;