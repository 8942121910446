import React from 'react';
import TopNavBar from '../TopNavBar';
import DashboardMenu from './DashboardMenu';

const DashboardWrapper = ({ title, children }) => {
  return (
    <div className='t-flex t-flex-col'>
      <section className='t-pt-6'>
        <div className='container'>
          <TopNavBar title={title} />
        </div>
      </section>
      <div className='t-flex t-flex-row t-gap-12 section-padding container'>
        <div className='t-hidden lg:t-block'>
            <DashboardMenu />
        </div>
        {children}
      </div>
    </div>
  );
};

export default DashboardWrapper;
