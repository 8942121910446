import React from "react";

const Button = ({index, component, onClick}) => {

    const handleClick = (e) => {
        // Call the onClick prop when the button is clicked and pass the component as an argument
        onClick(e, component);
      };

    return(
        <button key={index} className={`${component.class}`} onClick={handleClick}>
            <div dangerouslySetInnerHTML={{ __html: component.label}} />
        
        </button>
    );

}

export default Button;
