import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useGetBlogDetailsQuery } from '../../features/blogApiSlice';
import { Helmet } from 'react-helmet';
import { OurIngredients } from '../../Constants';
import { useTranslation } from 'react-i18next';
import { Buffer } from 'buffer';
import { useGetContentsQuery } from '../../features/contentsApiSlice';
import {
  selectSlug,
  selectTakeTheTestTitle,
} from '../../features/contentsSlice';
import { useSelector } from 'react-redux';
import { ExternalLink } from 'react-external-link';
import { selectCurrentLanguage } from '../../features/langSlice';

const IngredientDetails = () => {
  const { slug } = useParams();
  const takeTheTestTitle = useSelector(selectTakeTheTestTitle);
  const slugg = useSelector(selectSlug);
  const idSlug = Buffer.from('57').toString('base64');
  const [bottomData, setBottom] = useState();
  const [skip] = useState(false);

  const [data, setData] = useState();
  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState();
  const { t } = useTranslation();

  const {
    data: fetchDetails,
    isSuccess,
    refetch,
  } = useGetBlogDetailsQuery({ slug: slug, lang: lang });

  const { data: fetchBottom, isSuccess: isSuccessBottom } = useGetContentsQuery(
    { id: idSlug, lang: lang, skip: skip }
  );

  useEffect(() => {
    setLang(selectedLang === 'us' ? 'en' : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    if (isSuccessBottom) {
      if (fetchBottom.data) {
        setBottom(fetchBottom.data);
      }
    }
  }, [fetchBottom, isSuccessBottom]);

  useEffect(() => {
    if (isSuccess) {
      setData(fetchDetails.data[0]);
    }
  }, [fetchDetails, isSuccess]);

  return (
    <>
      <Helmet>
        <title>{`Riise - ${data?.title}`}</title>
        <meta name='title' content={data?.meta_title} />
        <meta name='description' content={data?.meta_description} />
      </Helmet>
      <section className="steps section-padding !t-pb-2 !t-pt-5 !t-bg-gray-100">
          <div className='container'>
            <nav aria-label='Page breadcrumb'>
              {/*<h2 className='title2'>{t(OurIngredients)}</h2>*/}
              <ol className='breadcrumb'>
                <li className='breadcrumb-item active' aria-current='page'>
                  <Link to={'/'} className='breadcrumb-item'>
                    Home
                  </Link>
                </li>
                <li className='breadcrumb-item active' aria-current='page'>
                  <Link
                    to={'/ingredients'}
                    className='breadcrumb-item'
                  >
                    {t(OurIngredients)}
                  </Link>
                </li>
                <li className='breadcrumb-item !t-text-gray-500'>
                  {data?.title}
                </li>
              </ol>
            </nav>
        </div>
      </section>

      <section className='blog-details section-padding'>
        <div className='container'>
          <div className='row gx-sm-5 gy-4'>
            <div className='col-xl-8'>
              <div className='blog-detail-inner'>
                <h3 className='title3'>{data?.title}</h3>
                <span dangerouslySetInnerHTML={{ __html: data?.contents }} />
              </div>
            </div>
            <div className='col-xl-4'>
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}${data?.picture}`}
                alt=''
                className='img-fluid'
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className='breadcrumbs-wrapper-partner'
        style={{
          backgroundImage: `linear-gradient(var(--tertiary-text),var(--tertiary-text)),  url(${process.env.REACT_APP_PUBLIC_URL}${bottomData?.picture})`,
        }}
      >
        <div className='container'>
          <div className='col-lg-7 mx-auto text-center px-3'>
            <h4 className='mb-4 top-head'>
              {bottomData?.title && (
                <span
                  className='fw-bold'
                  dangerouslySetInnerHTML={{
                    __html: bottomData?.title,
                  }}
                />
              )}
            </h4>
            <div className='text-center mt-4'>
              <Link to={slugg}>
                <span
                  className='btn btn-custom fw-bold lts1 px-3'
                  style={{ textTransform: 'none' }}
                >
                  {takeTheTestTitle}
                </span>
              </Link>
            </div>
            {bottomData && (
              <h5
                className='lh-base mt-3'
                dangerouslySetInnerHTML={{
                  __html: bottomData?.contents,
                }}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default IngredientDetails;
