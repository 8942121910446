import React from 'react';

const FocusAreaCard = ({ bg, icon, name }) => {
  return (
    <div
      className={`t-relative t-flex-none t-w-[150px] t-h-[30px] t-flex !i-items-center !t-py-[7px] t-gap-2 t-rounded-[0.2rem] t-px-2 t-ml-0 t-mr-2 `}
      style={{
        backgroundImage: `linear-gradient(180deg, rgba(29, 29, 31, 0.10) 0%, rgba(29, 29, 31, 0.75) 100%), url(${process.env.REACT_APP_PUBLIC_URL}${bg})`,
        backgroundSize: 'cover',
      }}
    >
      <img
        src={`${process.env.REACT_APP_PUBLIC_URL}${icon}`}
        alt=''
        className='t-w-4 t-h-4'
      />
      <p className='t-text-white t-font-[500] t-text-xs t-leading-4' style={{fontFamily: 'Roboto'}}>{name}</p>
    </div>
  );
};

export default FocusAreaCard;
