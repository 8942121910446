import React, { useRef } from 'react'
import { Icon } from './icon'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'

const Modal = ({
  open,
  handleClose,
  children,
  className,
  notCrossIcon,
  style,
  childrenClass,
}) => {
  const modalRef = useRef()
  useOnClickOutside(modalRef, handleClose)
  return (
    <>
      {open && (
        <div
          className="t-w-screen t-h-screen t-z-[9999] t-right-0 t-top-0 t-fixed t-shadow t-bg-background t-flex t-justify-center t-items-center m-0"
          style={{
            backgroundColor: 'rgba(0,0,0,0.7)',
            margin: '0px',
          }}
        >
          <div
            className={`t-relative t-bg-white t-text-black t-rounded-[0.5rem] t-px-4 t-xl:px-6 t-2xl:px-12 t-border-[1px] t-border-white ${className}`}
            style={style}
            ref={modalRef}
          >
            {notCrossIcon ? null : (
              <div className='t-sticky t-top-0 t-flex t-items-end t-justify-end t-bg-white t-py-2'>
              <Icon
                name='cross'
                size={20}
                className="t-self-end t-fill-black t-bg-white t-font-bold t-rounded-full t-cursor-pointer"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleClose()
                }}
              />
              </div>
            )}

            <div
              className={`t-flex t-flex-col t-items-center t-justify-center t-w-full t-gap-4 ${childrenClass}`}
            >
              {children}
            </div>
            
          </div>
        </div>
      )}
    </>
  )
}

export { Modal }
