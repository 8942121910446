import React from "react";
import { useGetContentsByTagWebQuery } from "../../../features/contentsApiSlice";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../features/langSlice";

const ExpertSection = () => {
  const selectedLang = useSelector(selectCurrentLanguage);
  // data[6], data[7] are experts
  const { data: content } = useGetContentsByTagWebQuery({
    tag: "home",
    lang: selectedLang === null || selectedLang === "us" ? "en" : selectedLang,
    skip: false,
  });
  console.log("content", content);

  return (
    <div className="t-flex t-flex-row t-gap-4 t-items-start t-justify-between">
      <ExpertCard
        image={content?.data[6]?.picture}
        name={content?.data[6]?.title}
        content={content?.data[6]?.contents}
      />
      <ExpertCard
        image={content?.data[7]?.picture}
        name={content?.data[7]?.title}
        content={content?.data[7]?.contents}
      />
    </div>
  );
};

const ExpertCard = ({ image, name, content }) => (
  <div className="t-flex t-flex-col t-items-center t-gap-2 t-justify-center">
    <img
      src={process.env.REACT_APP_PUBLIC_URL + image}
      alt=""
      className="t-h-32 t-w-32"
    />
    <p className="t-m-0 t-text-lg t-font-[600] t-text-center">{name}</p>
    <h5
      className="t-text-sm t-text-tertiary t-font-bold t-text-center"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </div>
);

export default ExpertSection;
