import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import { updatePasswordActions } from '../../app/api/useDataManage/userActions';

function ChangePassword() {
  const token = localStorage.getItem('customer_token');
  const { message } = useSelector((state) => state.user);
  const [hasDisplayedError, setHasDisplayedError] = useState(true);

  const dispatch = useDispatch();
  const [password, setUpdatePassword] = useState({
    current_password: '',
    new_password: '',
    confirm_new_password: '',
  });

  useEffect(() => {
    if (message === 'updated successfully' && !hasDisplayedError) {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setHasDisplayedError(true);
    } else if (message !== '' && !hasDisplayedError) {
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setHasDisplayedError(true);
    }
  }, [message, hasDisplayedError]);

  const [passwordError, setPasswordError] = useState('');
  const passChangeHandler = (e) => {
    setUpdatePassword({ ...password, [e.target.name]: e.target.value });
  };

  const isValidPassword = () => {
    const { current_password, new_password, confirm_new_password } = password;
    if (!current_password || !new_password || !confirm_new_password) {
      setPasswordError('Please provide values for all fields.');
      return false;
    }
    if (
      new_password.length < 8 ||
      !/[A-Z]/.test(new_password) ||
      !/[a-z]/.test(new_password)
    ) {
      setPasswordError(
        'Password should contain at least one uppercase letter, one lowercase letter, and be at least 8 characters long.'
      );
      return false;
    }

    if (new_password !== confirm_new_password) {
      setPasswordError('New password and confirm password do not match.');
      return false;
    }

    setPasswordError('');
    return true;
  };
  const passSubmitHandler = (e) => {
    e.preventDefault();
    if (!isValidPassword()) {
      return;
    }
    const passData = {
      data: password,
      token: token,
    };
    dispatch(updatePasswordActions(passData));
    setHasDisplayedError(false);
  };

  return (
    <div className='container'>
      <div className='welcome mb-5'>
        <h4 className='t-font-bold'>Change Password </h4>
      </div>
      <form onSubmit={passSubmitHandler}>
        <div className='row gy-4'>
          {passwordError && (
            <div className='text-danger mt-2'>{passwordError}</div>
          )}
          <div className='col-lg-12 position-relative'>
            <label className='t-text-gray-600 !t-ml-0'>Current Password</label>
            <input
              type='password'
              name='current_password'
              id='current_password'
              className='form-control t-h-[40px] !t-pl-3'
              placeholder='Current Password'
              aria-describedby='helpId'
              value={password.current_password}
              onChange={passChangeHandler}
            />
          </div>
          <div className='col-lg-12 position-relative'>
            <label className='t-text-gray-600 !t-ml-0'>New Password</label>
            <input
              type='password'
              name='new_password'
              id='new_password'
              className='form-control t-h-[40px] !t-pl-3'
              placeholder='New Password'
              aria-describedby='helpId'
              value={password.new_password}
              onChange={passChangeHandler}
            />
          </div>
          <div className='col-lg-12 position-relative'>
            <label className='t-text-gray-600 !t-ml-0'>Confirm Password</label>
            <input
              type='password'
              name='confirm_new_password'
              id='confirm_new_password'
              className='form-control t-h-[40px] !t-pl-3'
              placeholder='Confirm Password'
              aria-describedby='helpId'
              value={password.confirm_new_password}
              onChange={passChangeHandler}
            />
          </div>
        </div>
        <div className='t-mt-6'>
          <button
            type='submit'
            className='btn btn-custom-secondary fw-bold lts1 px-5'
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
