import {
    Cruelty,
    European,
    Gluten,
    Lactose,
    NoAdditives,
    Vegan,
    AthletesDopping,
  } from "../Data/Images/Home";

export const INGREDIENTS_MARGIN_FACTOR = [
  { id: 1, name: "Ashwagandha", margin_factor: 3 },
  { id: 25, name: "Vitamin B7 (Biotin)", margin_factor: 4 },
  { id: 4, name: "Calcium", margin_factor: 3 },
  { id: 5, name: "Chromium", margin_factor: 4 },
  { id: 6, name: "Coenzyme Q10", margin_factor: 3 },
  { id: 7, name: "Copper", margin_factor: 1 },
  { id: 11, name: "Iron", margin_factor: 4 },
  { id: 12, name: "Magnesium", margin_factor: 3 },
  { id: 13, name: "Manganese", margin_factor: 4 },
  { id: 17, name: "Selenium", margin_factor: 4 },
  { id: 18, name: "Vitamin A", margin_factor: 4 },
  { id: 20, name: "Vitamin B12", margin_factor: 2 },
  { id: 21, name: "Vitamin B2", margin_factor: 4 },
  { id: 24, name: "Vitamin B6", margin_factor: 4 },
  { id: 26, name: "Vitamin B9 (Folat)", margin_factor: 4 },
  { id: 27, name: "Vitamin C", margin_factor: 4 },
  { id: 28, name: "Vitamin D3", margin_factor: 5 },
  { id: 29, name: "Vitamin E", margin_factor: 4 },
  { id: 30, name: "Vitamin K", margin_factor: 4 },
  { id: 19, name: "Vitamin B1", margin_factor: 4 },
  { id: 22, name: "Vitamin B3 (Niacin)", margin_factor: 3.5 },
  { id: 23, name: "Vitamin B5 (Pantothenic acid)", margin_factor: 4 },
  { id: 16, name: "Potassium", margin_factor: 3.5 },
  { id: 8, name: "Curcuma", margin_factor: 3.5 },
  { id: 31, name: "Zinc", margin_factor: 4 },
];

export const ICONS_DATA = [
    Cruelty,
    European,
    Gluten,
    Lactose,
    NoAdditives,
    Vegan,
    AthletesDopping,
  ];

export const calculateIngredientPrice = (
  ingredient,
  quantity,
  setPricesData,
  setPaymentLink,
  setSubscription,
  allIngredientPrices
) => {
  if (quantity > 0) {
    const ingredientPriceData = allIngredientPrices?.data?.find(
      (item) => item?.ingredient_id === ingredient?.ingredient_id
    );
    const ingredientMarginFactor = INGREDIENTS_MARGIN_FACTOR?.find(
      (item) => item?.id === ingredient?.ingredient_id
    );
    const dailyDose =
      quantity / parseFloat(ingredientPriceData?.active_percentage);
    const thirtyDaysDose =
      parseFloat(ingredientPriceData?.quote_per_mg) * dailyDose * 30;
    const finalPrice =
      (thirtyDaysDose * ingredientMarginFactor?.margin_factor + 0.6) * 1.17 * 1.07; // 1.17 is commission rate, 0.6 operation rate, 1.07 is vat
      setPricesData((prev) => ({
      ...prev,
      [ingredient?.ingredient]: finalPrice.toFixed(2),
    }));
  } else {
    setPricesData((prev) => ({ ...prev, [ingredient?.ingredient]: 0 }));
  }
  setPaymentLink(null);
  setSubscription({
    title: "",
    duration: "",
    description: "",
    subId: 1,
    expirey: Date,
  });
};
