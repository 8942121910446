import React, { useEffect, useState } from 'react';

const ImageCheckbox = ({ component, onChange, className, isPrevChecked }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();

    const label1 = document.getElementById(component.name);
    if (label1) {
      if (!label1.classList.contains('checked')) {
        label1.classList.add('checked');

        setIsChecked(false);
      } else {
        label1.classList.remove('checked');
      }
    }

    const newValue = !isChecked;
    setIsChecked(newValue);
    onChange(newValue);
  };

  return (
    <label
      id={component.name}
      code={component.answerCode}
      className={`image-checkbox ${className}`}
      onClick={handleClick}
    >
      {
        component.img ? (
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}${component.img}`}
            className='mx-auto'
            // onClick={handleClick}
            alt='Checkbox'
          />
        ) : null
      }
      {/* <input
        type="checkbox"
        id={component.name}
        checked={checked} 
        onChange={() => {}}
      /> */}
      <div dangerouslySetInnerHTML={{ __html: component.label }} />
    </label>
  );
};

export default ImageCheckbox;
