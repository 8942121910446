import React from "react";

const ImageButton = ({index, component, onClick}) => {

    const handleClick = (e) => {
        // Call the onClick prop when the button is clicked and pass the component as an argument
        onClick(e, component);
      };

    return(

        <button key={index} onClick={handleClick} className="img-button">
            {
                component.img ? (
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}${component.img}`} alt=""/>
                ) : null
            }
            <span className="text" dangerouslySetInnerHTML={{ __html: component.label}} />
        </button>
    );

}

export default ImageButton;
