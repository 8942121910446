import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLanguage } from '../../features/langSlice';
import { useGetContentsQuery } from '../../features/contentsApiSlice';
import { Buffer } from 'buffer';

const Benefits = () => {
  const id = Buffer.from('60').toString('base64');
  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState();
  const [data, setData] = useState([]);

  const [skip] = useState(false);
  const {
    data: fetchContents,
    isSuccess,
    refetch,
  } = useGetContentsQuery({ id: id, lang: lang, skip: skip });

  useEffect(() => {
    setLang(selectedLang === 'us' ? 'en' : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    if (isSuccess) {
      setData(fetchContents.data);
    }
  }, [fetchContents, isSuccess]);

  return (
    <div className='col-xl-8 col-lg-8 user-inner'>
      <div className='blog-detail-inner !t-text-base'>
        <span dangerouslySetInnerHTML={{ __html: data.contents }} />
      </div>
    </div>
  );
};

export default Benefits;
