import { apiSlice } from "../app/api/apiSlice";

export const whyriiseApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getRecord: builder.query({
            query: (arg) => {
                const { lang } = arg;
                return {url: `whyriiseweb?lang=${lang}`}
            },
            validateStatus: (response, result) => {
                console.log(result)
                return response.status === 200 && !result.isError
            },
        }),
    })
})

export const {
    useGetRecordQuery
} = whyriiseApiSlice