import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_BASE_URL}`,
  prepareHeaders: (headers, { getState }) => {
    let token = getState().auth.token;
    if (null === token) {
      token = localStorage.getItem('customer_token');
    }
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Origin', '*');
    headers.set(
      'Access-Control-Allow-Headers',
      'Origin, X-Requested-With, Content-Type, Accept'
    );
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (
    result?.error?.status === 401 &&
    result?.error?.data?.message === 'Unauthorized'
  ) {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    const i18nextLng = localStorage.getItem('i18nextLng');
    localStorage.clear();
    localStorage.setItem('cookiesAccepted', cookiesAccepted);
    localStorage.setItem('i18nextLng', i18nextLng);
    toast.error('Session expired! Please login again')
    setTimeout(()=>{
      window.location.replace('/login')
    }, 3000)
  } else if (result?.error?.originalStatus === 401) {
    const cookiesAccepted = localStorage.getItem('cookiesAccepted');
    const i18nextLng = localStorage.getItem('i18nextLng');
    localStorage.clear();
    localStorage.setItem('cookiesAccepted', cookiesAccepted);
    localStorage.setItem('i18nextLng', i18nextLng);
    toast.error('Session expired! Please login again')
    setTimeout(()=>{
      window.location.replace('/login')
    }, 3000)
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
