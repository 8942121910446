import React, { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSendInquiryMutation } from '../features/contactApiSlice';
import { useSelector } from 'react-redux';
import { selectCurrentLanguage } from '../features/langSlice';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';

import { SendInquiry, Message } from '../Constants';

const Inquiry = () => {
  const [message, setMessage] = useState('');

  const [error, setError] = useState('');

  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState();

  const [sendInquiry] = useSendInquiryMutation();
  const [inquirySending, setInquirySending] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setLang(selectedLang === 'us' ? 'en' : selectedLang);
  }, [selectedLang, lang]);

  const onSendInquiry = async () => {
    const data = {
      message: message,
    };

    console.log(data);

    if (validate(data)) {
      try {
        setInquirySending(true);
        const res = await sendInquiry(data).unwrap();

        if (res.message) {
          clear();

          console.log(res);

          toast.success(
            'Thank you for your message. We are working on it and reply to you via email.'
          );
        } else {
          toast.error('Something is wrong!');
        }

        setInquirySending(false);
      } catch (err) {
        console.log(err);
        toast.error('Something is wrong!');
      }
    }
  };

  const validate = (data) => {
    console.log(data.message);
    setError('');
    if (data.message === undefined || data.message === '') {
      setError('Please enter your message');
      return false;
    }

    return true;
  };

  const clear = () => {
    setError('');
    setMessage('');
  };

  return (
    <Fragment>
      <section className='inquiry-section section-padding pt-0'>
        <div className='container'>
          <div className='title-wrapper !t-mb-6'>
            <h4 className='t-font-bold t-text-black'>{t(SendInquiry)}</h4>
          </div>
          <div>
            <div className='row t-mt-0'>
              <div className='col-lg-12 position-relative'>
                <textarea
                  rows={5}
                  className='form-control !t-rounded-lg !t-p-3'
                  placeholder={t(Message)}
                  aria-describedby='helpId'
                  defaultValue={''}
                  value={message}
                  maxLength={500}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className='text-danger'>{error}</div>
            <div className='t-mt-6'>
              <button
                className='btn btn-custom-secondary fw-bold lts1 px-5'
                onClick={onSendInquiry}
                disabled={inquirySending}
              >
                {inquirySending && (
                  <Spinner style={{ width: '20px', height: '20px' }} />
                )}
                &nbsp;&nbsp;{t(SendInquiry)}
              </button>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Inquiry;
