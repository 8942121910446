import React, { useState } from "react";
import { Icon } from "../elements/icon";

const NewAccordion = ({ image, heading,borderBottom=true, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={`t-flex t-flex-col t-gap-0 t-px-2 ${borderBottom && "t-border-b-[1px] t-border-gray-300"} `}>
      <div
        className="t-h-12 t-flex t-flex-row t-gap-4 t-items-center t-justify-between t-cursor-pointer"
        onClick={() => setOpen(!open)}
      >
        <div className="!t-h-fit t-flex !t-items-center !t-gap-0">
          <img src={image} alt="" className="t-h-6 t-w-6 t-bg-green-500" />
          <p className="t-text-base md:t-text-lg t-text-gray-900 !t-m-0 !t-leading-6 t-font-bold">
            {heading}
          </p>
        </div>
        <Icon
          name={open ? "minus" : "plus"}
          color="#667085"
          size="28"
        />
      </div>
      {open ? <div className="t-w-full !t-m-0">{children}</div> : null}
    </div>
  );
};

export { NewAccordion };
