import { apiSlice } from "../app/api/apiSlice";

export const customerApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLastQuiz: builder.mutation({
      query: (payload) => ({
        url: "customer/lastquizdetail",
        method: "POST",
        body: { ...payload },
      }),
    }),
    compareQuizWeb: builder.mutation({
      query: (payload) => ({
        url: "customer/compareQuizWeb",
        method: "POST",
        body: { ...payload },
      }),
    }),
    updateQuizVerWeb: builder.mutation({
      query: (payload) => ({
        url: "customer/updateQuizVerWeb",
        method: "POST",
        body: { ...payload },
      }),
    }),
    getUserSubscription: builder.query({
      query: () => ({ url: `customer/getUserSubscription` }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
  }),
});

export const {
  useGetLastQuizMutation,
  useCompareQuizWebMutation,
  useUpdateQuizVerWebMutation,
  useGetUserSubscriptionQuery,
} = customerApiSlice;
