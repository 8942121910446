import React, { useState, useEffect } from 'react';
import { Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';

import { selectCurrentLanguage } from '../features/langSlice';
import { useGetFaqsQuery } from '../features/contentsApiSlice';
import { Link } from 'react-router-dom';
import { FAQs, AnyOtherQuestion } from '../Constants/index';
import { useTranslation } from 'react-i18next';

const Faqs = () => {
  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState();
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(0);
  const [skip] = useState(false);

  const {
    data: fetchFaqs,
    isSuccess,
    refetch,
  } = useGetFaqsQuery({ lang: lang, skip: skip });

  useEffect(() => {
    setLang(selectedLang === 'us' ? 'en' : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    if (isSuccess) {
      setData(fetchFaqs.data);
    }
  }, [fetchFaqs, isSuccess]);

  const toggle = (id) => {
    isOpen === id ? setIsOpen(null) : setIsOpen(id);
  };

  return (
    <>
      <section className='t-flex t-flex-col'>
        <div className='t-pt-6'>
          <div className='container '>
            <nav aria-label='Page breadcrumb'>
              <h2 className='title2 !t-text-[24px]'>{t(FAQs)}</h2>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item active' aria-current='page'>
                  <Link to={'/'} className='breadcrumb-item  !t-text-gray-500'>
                    Home
                  </Link>
                </li>
                <li className='breadcrumb-item  !t-text-gray-500'>{t(FAQs)}</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className='blog-details section-padding'>
        <div className='container'>
          <div className='row gx-sm-5 gy-4'>
            <div className='col-xl-12'>
              <div className='blog-detail-inner faq-inner'>
                <Accordion className='accordion mt-5' defaultActiveKey='0'>
                  {data.map((item, index) => (
                    <Card className='accordion-item' key={index}>
                      <h2
                        className='accordion-header'
                        id={`accordionItem${index}`}
                      >
                        <button
                          as={CardHeader}
                          type='button'
                          className='accordion-button accordion-button-arrow collapsed'
                          onClick={() => toggle(index + 1)}
                        >
                          {item.title}
                        </button>
                      </h2>

                      <Collapse
                        className='accordion-collapse collapse'
                        isOpen={isOpen === index + 1}
                        id={`accordionItem${index}`}
                      >
                        <CardBody className='accordion-body'>
                          <p>{item.contents}</p>
                        </CardBody>
                      </Collapse>
                    </Card>
                  ))}
                </Accordion>

                <div>
                  <p>
                    {t(AnyOtherQuestion)}{' '}
                    <span style={{ color: 'blue' }}>hi@weriise.co</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faqs;
