import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const URL = `${process.env.REACT_APP_BASE_URL}`;
// export const createPayPalActions = createAsyncThunk(
//   "paypal/action",
//   async (orderData) => {
//     try {
//       const { data } = await axios.post(`${URL}paypalOrder`, orderData);
//       return data.message;
//     } catch (error) {
//       let message = error.response.data.message;
//       return message;
//     }
//   }
// );

export const createOrderActions = createAsyncThunk(
  "order/create",
  async (orderData) => {
    try {
      const { data } = await axios.post(`${URL}createOrder`, orderData);
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getUserDiscountsActions = createAsyncThunk(
  "user/discounts",
  async (code) => {
    try {
      console.log("code", code);
      const { data } = await axios.post(`${URL}getDiscounts`, { code });

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getOrderAction = createAsyncThunk("order/get", async (quiz_id) => {
  try {
    console.log('quiz_id: ',quiz_id);
    const { data } = await axios.post(`${URL}getUserOrder`, {quiz_id: quiz_id});
    console.log(data);

    return data.orders;
  } catch (error) {
    console.log("error", error.message);
  }
});

export const userUsedDiscountAction = createAsyncThunk(
  "discount/user",
  async (data) => {
    try {
      console.log(data);
      const response = await axios.post(`${URL}createUserDiscount`, data);
      return response.data;
    } catch (error) {
      console.log("Error during creating User Discount", error.message);
    }
  }
);

export const getReferreCodeAction = createAsyncThunk(
  "referal/code",
  async () => {
    try {
      const { data } = await axios.get(`${URL}getreferrerCodes`);
      return data.referrer_codes;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const createUserReferalCodeAction = createAsyncThunk(
  ("referalCode/create",
  async (referalData) => {
    try {
      console.log(referalData);
      console.log(URL);
      const { data } = await axios.post(
        `${URL}createCustomerReferralCode`,
        referalData
      );

      console.log(data);
    } catch (error) {
      console.log("Error", error.message);
    }
  })
);

export const updateUserReferalCodeAction = createAsyncThunk(
  ("referalCode/update",
  async (referalData) => {
    try {
      const { data } = await axios.post(
        `${URL}updateCustomerReferralCode`,
        referalData
      );
      console.log(data);
    } catch (error) {
      console.log("Error", error.message);
    }
  })
);

export const getCustomerReferrerByIdActions = createAsyncThunk(
  "referal/getCust",
  async (id) => {
    try {
      console.log(id);

      const { data } = await axios.get(`${URL}getCustomerReferrerById/${id}`);
      return data.customer_referal_Code;
    } catch (error) {
      console.log("error", error.message);
    }
  }
);
