import { apiSlice } from '../../app/api/apiSlice';

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: '/customer/login',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    register: builder.mutation({
      query: (credentials) => ({
        url: '/customer/register',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    sendLogout: builder.mutation({
      query: () => ({
        url: '/customer/logout',
        method: 'get',
      }),
    }),
    customerIsNew: builder.mutation({
      query: () => ({
        url: '/customer/isnew',
        method: 'get',
      }),
    }),
    customerCheckIn: builder.mutation({
      query: (credentials) => ({
        url: '/customer/checkin',
        method: 'PUT',
        body: { ...credentials },
      }),
    }),
    resetPasswordLink: builder.mutation({
      query: (credentials) => ({
        url: '/resetPasswordLink',
        method: 'POST',
        body: { ...credentials },
      }),
    }),
    changeUserPassword: builder.mutation({
      query: (credentials) => ({
        url: '/changeUserPassword',
        method: 'PUT',
        body: { ...credentials },
      }),
    }),
    createNewPassword: builder.mutation({
      query: (credentials) => ({
        url: '/customer/createNewUserPass',
        method: 'PUT',
        body: { ...credentials },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useSendLogoutMutation,
  useCustomerIsNewMutation,
  useCustomerCheckInMutation,
  useResetPasswordLinkMutation,
  useChangeUserPasswordMutation,
  useCreateNewPasswordMutation
} = authApiSlice;
