import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useGetRecordQuery } from '../../features/whyriiseApiSlice';
import { selectCurrentLanguage } from '../../features/langSlice';
import { WhyRiiseTitle } from '../../Constants';
import { Accordion } from '../elements/Accordion';

const ProductSuggestionWhyRiise = () => {
  const [data, setData] = useState([]);
  const [skip] = useState(false);

  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState('');
  const { t } = useTranslation();

  const {
    data: fetchRecords,
    isSuccess,
    refetch,
  } = useGetRecordQuery({ lang: lang, skip: skip });

  useEffect(() => {
    if (isSuccess) {
      if (fetchRecords.data) {
        setData(fetchRecords.data);
      }
    }
  }, [fetchRecords, isSuccess]);

  useEffect(() => {
    setLang(selectedLang === 'us' ? 'en' : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  return (
    <div className='t-flex t-flex-col t-gap-4'>
      <Accordion heading={`${t(WhyRiiseTitle)}?`}>
        {data &&
          data.map((item, index) =>
            item.category === 'whyriise' ? (
              <div className='t-flex t-items-starts t-gap-1' key={'whiriise'+index}>
                {
                  item.picture && (
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}${item.picture}`} alt='' className='t-h-6 t-w-8 t-object-cover' />
                  )
                }
                <div  className='t-flex t-flex-col t-gap-0'>
                  <p className='t-font-bold'>{item.title}</p>
                  <p>{item.description}</p>
                </div>
              </div>
            ) : (
              ''
            )
          )}
      </Accordion>
      {data &&
        data.map((item, index) =>
          item.category === 'faq' ? (
            <Accordion heading={item.title} key={index}>
              <p>{item.description}</p>
            </Accordion>
          ) : (
            ''
          )
        )}
    </div>
  );
};

export default ProductSuggestionWhyRiise;
