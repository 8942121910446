import React, {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import { Accepted } from "../Constants";
import {useDispatch, useSelector} from "react-redux";
import {selectCookieBannerMessage, setCookieBannerMessage} from "../features/contentsSlice";
import { useGetContentsByTagWebQuery } from "../features/contentsApiSlice";
import { selectCurrentLanguage } from "../features/langSlice";

const CookieBanner = () => {
    const [showBanner, setShowBanner] = useState(true);
    const {t} = useTranslation()
    const cookieBannerMessage = useSelector(selectCookieBannerMessage)
    const dispatch = useDispatch();
    const selectedLang = useSelector(selectCurrentLanguage);
    const [lang, setLang] = useState();

    const {
        data: fetchContents,
        isSuccess,
        refetch,
      } = useGetContentsByTagWebQuery({ tag: "home", lang: lang ? lang : "en", skip: false });
    
      useEffect(() => {
        setLang(selectedLang === "us" ? "en" : selectedLang);
    
        refetch();
      }, [refetch, lang, selectedLang]);
    
      useEffect(() => {
        if (isSuccess) {
          if (fetchContents.data.length > 0) {

            dispatch(
              setCookieBannerMessage({ message: fetchContents.data[23].contents })
            );
          }
        }
      }, [fetchContents]);


    const acceptCookies = () => {
        // Set a cookie or use local storage to remember the user's preference
        // Example using local storage:
        localStorage.setItem('cookiesAccepted', 'true');
        setShowBanner(false);
    };

    // Check if the user has already accepted cookies
    const cookiesAccepted = localStorage.getItem('cookiesAccepted') === 'true';

    if (!cookiesAccepted && showBanner) {
        return (
            <Fragment>
                <div className="cookie-banner">
                    <span dangerouslySetInnerHTML={{__html: cookieBannerMessage}} />
                    <button className="btn btn-custom me-xl-3 mb-3 px-4" onClick={acceptCookies}>{t(Accepted)}</button>
                </div>
            </Fragment>
        );
    }
    return null;
}

export default CookieBanner;