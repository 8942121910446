import React, { useState } from "react";
import { Modal } from "../../elements/Modal";
import { Icon } from "../../elements/icon";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../../../features/langSlice";

const AddIngredientModal = ({
  open,
  handleClose,
  allIngredients,
  data,
  removeIngredient,
  addIngredient,
}) => {
  return (
    <Modal
      open={open}
      handleClose={handleClose}
      className="t-w-[300px] t-max-h-[70vh] md:t-max-h-[50vh] !t-px-4 t-overflow-scroll"
      childrenClass=""
    >
      <div className="t-h-full t-flex t-flex-col t-items-center t-justify-center t-gap-4 t-py-4 t-overflow-scroll">
        {allIngredients?.data?.map((item) => (
          <Row
            key={"item-row-" + item.id}
            data={data}
            removeIngredient={removeIngredient}
            addIngredient={addIngredient}
            item={item}
          />
        ))}
      </div>
    </Modal>
  );
};

const Row = ({ data, removeIngredient, addIngredient, item }) => {
  const selectedLang = useSelector(selectCurrentLanguage);
  const [info, setInfo] = useState(false);
  return (
    <>
      <div className="t-w-full t-flex t-items-center t-justify-between t-gap-8">
        <div className="t-flex t-items-center t-gap-2">
          {data?.find((ing) => ing?.ingredient_id === item?.id) ? (
            <Icon
              name="checkCircle"
              size="18"
              color="#003b4d"
              onClick={() => removeIngredient(item)}
            />
          ) : (
            <Icon
              name="plus"
              size="18"
              color="#003b4d"
              onClick={() => addIngredient(item)}
            />
          )}

          <p className="t-m-0">
            {selectedLang === "de" ? item?.ingredient_de : item?.ingredient}
          </p>
        </div>
        <Icon
          name="infoFilled"
          size="18"
          color="#003b4d"
          onClick={() => setInfo(!info)}
        />
      </div>
      {info && <p className="t-text-sm t-m-0">{item?.description}</p>}
    </>
  );
};

export default AddIngredientModal;
