import React, {useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../features/langSlice";
import { useGetContentsByTagWebQuery } from "../features/contentsApiSlice";
import { check_icon, search_icon, hand_icon } from "../Data/Images/About";
import { expert_avatar, expert_avatar1 } from "../Data/Images/Home";
import { Buffer } from "buffer";
import {use} from "i18next";

const AboutUs = () => {
    const selectedLang = useSelector(selectCurrentLanguage)
    const [lang, setLang] = useState ()
    const id = Buffer.from('4').toString('base64')

    
    
    const [data, setData] = useState([])
    const [skip, setSkip] = useState(false);

    const [banner, setBanner] = useState('')
    const [pictures, setPictures] = useState([])
    
    
    const { data: fetchContents, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetContentsByTagWebQuery({tag: 'About', lang:lang, skip: skip});


    useEffect(() => {

        setLang(selectedLang==='us'?'en':selectedLang)

        refetch()

    },[refetch, lang, selectedLang]);

    useEffect(() => {

        if(isSuccess) {

            setData(fetchContents.data)
            //console.log(fetchContents.data)

            //setPictures([])

            if(fetchContents.data[0]) {
                setBanner(`${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[0].picture}`)
                pictures.push(`${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[0].picture}`)
                pictures.push(`${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[2].picture}`)
                pictures.push(`${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[3].picture}`)
                pictures.push(`${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[4].picture}`)
                pictures.push(`${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[5].picture}`)

                pictures.push(`${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[7].picture}`)
                pictures.push(`${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[8].picture}`)


                //console.log(`${process.env.REACT_APP_PUBLIC_URL}${fetchContents.data[3].picture}`)
            }
        }

    },[fetchContents]);

    return (
        <>
            <section className="breadcrumbs-wrapper-new" style={{backgroundImage: `url(${banner})`}}>
            <div className="container">
                <div className="col-lg-7 mx-auto text-center px-3">
                    <h4 className="mb-4">
                        {data[0]&&<div dangerouslySetInnerHTML={{__html:data[0].contents}} />}
                    </h4>
                </div>
            </div>
        </section>

        <section className="difference-section inner-page">
            <div className="container">
            <div className="col-lg-10 mx-auto">
                <div className="title-wrapper">
                <h3 className="title2 text-center mb-4">
                {data[1]&&<div dangerouslySetInnerHTML={{__html:data[1].title}} />}
                </h3>
                <div className="text-start">
                    <h4 className="lh-base">
                        {data[1]&&<div dangerouslySetInnerHTML={{__html:data[1].contents}} />}
                    </h4>
                </div>
                <div className="solution-boxes">
                    <div className="row gy-4">
                        <div className="col-xl-3 col-md-6">
                            <div className="solution-box">
                            <img
                                src={ pictures[1] }
                                alt=""
                                className="img-fluid"
                            />
                            <h5>{data[2]&&<div dangerouslySetInnerHTML={{__html:data[2].contents}} />}</h5>
                            </div>
                        </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="solution-box">
                        <img
                            src={pictures[2]}
                            alt=""
                            className="img-fluid"
                        />
                        <h5>{data[3]&&<div dangerouslySetInnerHTML={{__html:data[3].contents}} />}</h5>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="solution-box">
                        <img
                            src={pictures[3]}
                            alt=""
                            className="img-fluid"
                        />
                        <h5>{data[4]&&<div dangerouslySetInnerHTML={{__html:data[4].contents}} />}</h5>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-6">
                        <div className="solution-box">
                        <img
                            src={pictures[4]}
                            alt=""
                            className="img-fluid"
                        />
                        <h5>{data[5]&&<div dangerouslySetInnerHTML={{__html:data[5].contents}} />}</h5>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>
        <section className="adventure-section section-padding pt-0 inner-page">
            <div className="container">
            <div className="title-wrapper text-center col-lg-10 mx-auto mb-4">
                <h3 className="title2 mb-4">{data[6]&&<div dangerouslySetInnerHTML={{__html:data[6].title}} />}</h3>
                <h4 className="text-start lh-base">
                {data[6]&&<div dangerouslySetInnerHTML={{__html:data[6].contents}} />}
                </h4>
            </div>
            <div className="col-lg-8 mx-auto text-center adventure-experts">
                <div className="row gy-4 mt-5">
                <div className="col-lg-6">
                    <img
                    src={pictures[5]}
                    alt=""
                    className="img-fluid"
                    />
                    <h4>{data[7]&&<div dangerouslySetInnerHTML={{__html:data[7].title}} />}</h4>
                    <h6>
                    {data[7]&&<div dangerouslySetInnerHTML={{__html:data[7].contents}} />}
                    </h6>
                </div>
                <div className="col-lg-6">
                    <img
                    src={pictures[6]}
                    alt=""
                    className="img-fluid"
                    />
                    <h4>{data[8]&&<div dangerouslySetInnerHTML={{__html:data[8].title}} />}</h4>
                    <h6>
                    {data[8]&&<div dangerouslySetInnerHTML={{__html:data[8].contents}} />}
                    </h6>
                </div>
                </div>
            </div>
            </div>
        </section>


        </>
    );
}

export default AboutUs;