import React, { useState, useEffect, useImperativeHandle, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import QuizSlide from "./QuizSlide";
import {
  useGetQuestionsQuery,
  useSaveIncompleteQuizMutation,
} from "../../features/quizApiSlice";
import axios from "axios";
// import { useGetLastQuizMutation } from '../../features/customerApiSlice';

const QuizModule = React.forwardRef(
  ({ setProgressValue, setShowProgressBar, progressValue }, ref) => {
    const location = useLocation();
    console.log("location quiz module", location);

    const [searchParams] = useSearchParams();
    const quizId = searchParams.get("quizId");
    const partner_id = searchParams.get("partner_id");
    // console.log('partner_id => ', partner_id)

    const [lang, setLang] = useState("");

    const [data, setData] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [slideValues, setSlideValues] = useState({});

    const history = useNavigate();

    const [classActive, setClassActive] = useState("");

    const [slideNavigation, setSlideNavigation] = useState([]);
    // console.log('slideNavigation -> ', slideNavigation)
    // console.log('slideValues -> ', slideValues)
    // const [getLastQuiz] = useGetLastQuizMutation();
    let uniq_id = 0;

    const previousRefe = useRef();

    useEffect(() => {
      const language =
        localStorage.getItem("lang") === "us"
          ? "en"
          : localStorage.getItem("lang");

      if (language) setLang(language);
      else setLang("en");
    }, []);

    // useEffect(() => {
    //   if(!state || state.quizType !== 'retake') return;

    //   setLang(selectedLang === 'us' ? 'en' : selectedLang);
    //   const fn = async () => {
    //     if (lang) {
    //       try {
    //         const reqData = { lang };

    //         console.log(reqData);
    //         const res = await getLastQuiz(reqData).unwrap();
    //         setSlideValues(res?.lastQuizData?.dataJson)
    //         setSlideNavigation(res?.lastQuizData?.slideNavigation)
    //         setCurrentSlide(10)

    //         console.log('LAST QUIX => ', res)
    //       } catch (err) {
    //         console.log('Error -> ', err);
    //       }
    //     }
    //   };

    //   fn();
    // }, [selectedLang, lang, getLastQuiz, state]);

    const { data: fetchQuestions, isSuccess } = useGetQuestionsQuery(lang);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getIncompleteQuiz = async () => {
      try {
        const data = await axios.get(
          `${process.env.REACT_APP_BASE_URL}quiz/incomplete?id=${quizId}`
        );
        setCurrentSlide(data?.data?.data?.currentSlide);
        setProgressValue(data?.data?.data?.progressBar);
        setSlideValues(data?.data?.data?.data);
        setSlideNavigation(data?.data?.data?.slideNavigation);
      } catch (err) {
        console.log("ERROR -> ", err);
      }
    };

    useEffect(() => {
      if (!quizId) return;
      getIncompleteQuiz();
    }, [quizId]);

    const [saveIncompleteQuiz] = useSaveIncompleteQuizMutation();

    useEffect(() => {
      const fn = () => {
        if (isSuccess) {
          setData(fetchQuestions.data);
          if (!quizId) {
            if (slideNavigation.length === 0) {
              // eslint-disable-next-line no-unused-vars
              for (const slide of fetchQuestions.data) {
                slideNavigation.push({ active: false });
              }
            }

            updateSlideNavigation([1], true);
            updateProgressBar(1);
          }

          setClassActive("active");

          localStorage.removeItem("quiz_id");
        }
      };
      fn();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchQuestions]);

    // this function for QuizSlide.jsx
    const previousHandle = (previousActiveSlide) => {
      if (previousRefe.current) {
        previousRefe.current.handlePrevious(previousActiveSlide);
      }
    };

    // this is calling from Quiz/index.jsx
    useImperativeHandle(ref, () => ({
      handlePrevious() {
        const previousActiveSlide = getPreviousActiveSlide();
        if (previousActiveSlide === undefined) {
          history(-1);
        }
        if (previousActiveSlide >= 0) {
          setClassActive("");

          // console.log(previousActiveSlide);
          setCurrentSlide(previousActiveSlide);

          setCurrentSlide(previousActiveSlide);

          setClassActive("active");

          updateProgressBar(previousActiveSlide);
        }
      },
    }));

    const updateSlideNavigation = (screenIds, active) => {
      screenIds.map((id, index) => {
        if (id > 0) slideNavigation[id - 1].active = active;
      });
    };

    const handleNext = () => {
      setClassActive("");

      const nextActiveSlide = getNextActiveSlide();
      setCurrentSlide(nextActiveSlide);

      setClassActive("active");

      updateProgressBar(nextActiveSlide + 1);
      if (currentSlide >= 11) {
        let email = "";
        if (document.getElementById("email").value) {
          email = document.getElementById("email").value.trim();
        } else if (slideValues[11]?.email?.value) {
          email = slideValues[11]?.email?.value.trim();
        }
        // const email = document.getElementById('email').value.trim();
        // console.log('EMAIL => ', email)
        saveIncompleteQuiz({
          email,
          currentSlide: currentSlide + 1,
          slideNavigation: slideNavigation,
          progressBar: progressValue,
          data: slideValues,
          partner_id,
          isCustomQuiz: location.pathname.includes("/custom-quiz"),
        }).then((res) => {
          console.log("save quiz res -> ", res);
        });
      }
    };

    const getNextActiveSlide = () => {
      const start = currentSlide + 1;

      for (let i = start; i < slideNavigation.length; i++) {
        if (slideNavigation[i].active === true) {
          return i;
        }
      }

      return currentSlide;
    };

    const getPreviousActiveSlide = () => {
      for (let i = currentSlide - 1; i >= 0; i--) {
        if (slideNavigation[i].active === true) {
          return i;
        }
      }
    };

    const slideData = (slideId, key, value, answerCode) => {
      if (key !== undefined) {
        setSlideValues((prevValues) => {
          // Create a copy of the previous values
          const updatedValues = { ...prevValues };

          // Check if the condition is met to remove the element
          if (value === false) {
            // Remove the specified key from the slideId object
            //console.log(slideId, key)
            if (updatedValues[slideId] && updatedValues[slideId][key])
              delete updatedValues[slideId][key];

            // If the slideId object becomes empty, remove the slideId itself

            if (
              updatedValues[slideId] &&
              Object.keys(updatedValues[slideId]).length === 0
            ) {
              delete updatedValues[slideId];
            }
          } else {
            // Update the state with the new values
            updatedValues[slideId] = {
              ...updatedValues[slideId],
              [key]: { value, answerCode },
            };
          }

          return updatedValues;
        });

        //
        // setSlideValues((prevValues) => ({
        //     ...prevValues,
        //     [slideId]: {
        //         ...prevValues[slideId],
        //         [key]: {value, answerCode},
        //     },
        // }));
      }

      //console.log(slideValues)
    };

    const updateProgressBar = (screenIndex) => {
      //(data.length -1) skip the last screen - summary
      let prog = (screenIndex / (data.length - 1)) * 100;

      if (parseInt(prog) > 100) {
        setShowProgressBar(false);
        //console.log('progress: ', prog)
      } else {
        setShowProgressBar(true);
      }

      if (prog < 0) prog = 0;

      setProgressValue(parseInt(prog));
    };

    const uniqid = () => {
      return uniq_id++;
    };
    return (
      <div className="text-center mt-4">
        {data.map((slide, index) => (
          <div className="text-center justify-content-center d-flex">
            <QuizSlide
              key={uniqid()}
              className={`row slide${index + 1} ${slide[0].class} ${
                index === currentSlide ? classActive : ""
              }`}
              currentSlide={currentSlide}
              slide={slide}
              handleNext={handleNext}
              slideValues={slideValues}
              slideData={slideData}
              updateSlideNavigation={updateSlideNavigation}
              slideNavigation={slideNavigation}
              uniqid={uniqid}
              ref={previousRefe}
              index={index}
              isLastSlide={index === data.length - 1}
            />
          </div>
        ))}
      </div>
    );
  }
);

export default QuizModule;
