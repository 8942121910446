import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom'; // Only useLocation here
import MetaPixel from '../utils/meta/MetaPixel';
import { useGetAnalyticsQuery } from '../features/AnalyticsApiSlice';

const RouteChangeTracker = () => {
  const location = useLocation();
  const [data, setData] = useState();
  const [omnisendId, setOmniSendId] = useState('');
  const { data: fetchAnalytics, isSuccess } = useGetAnalyticsQuery({
    type: 'embededscript',
    skip: false,
  });

  const options = {
    autoConfig: true,
    debug: false,
  };

  useEffect(() => {
    if (isSuccess) {
      setData(fetchAnalytics.data);
      if (fetchAnalytics.data.analytics_status === 1) {
        const analytics_id = fetchAnalytics.data.analytics_id;
        ReactGA.initialize(analytics_id, { debug: false });
        ReactGA.send("pageview");
      }
      if (fetchAnalytics.data.omnisend_status === 1) {
        setOmniSendId(fetchAnalytics.data.omnisend_id);
      }
    }
  }, [fetchAnalytics, isSuccess]);

  return (
    <div>
      {omnisendId !== '' && (
        <Helmet>
          <script type="text/javascript">
            {`
              window.omnisend = window.omnisend || [];
              omnisend.push(["accountID", "${omnisendId}"]);
              omnisend.push(["track", "$pageViewed"]);
              !function(){
                  var e = document.createElement("script");
                  e.type = "text/javascript", e.async = true,
                  e.src = "https://omnisnippet1.com/inshop/launcher-v2.js";
                  var t = document.getElementsByTagName("script")[0];
                  t.parentNode.insertBefore(e, t)
              }();
            `}
          </script>
        </Helmet>
      )}
      <MetaPixel options={options} />
    </div>
  );
};

export default RouteChangeTracker;