import React from "react";

const InfoCard = ({ image, desc, className, pClass }) => {
  return (
    <div className="t-w-1/3 t-flex t-flex-col t-items-center t-justify-center t-gap-2">
      <img src={image} alt="" className={`t-w-[80%] md:t-w-1/3 ${className}`} />
      <p className={`t-m-0 t-text-center ${pClass}`}>{desc}</p>
    </div>
  );
};

export default InfoCard;
