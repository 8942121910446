import React, {useState, useEffect} from "react";
import {Link, useParams} from "react-router-dom";

import { useGetBlogsQuery, useGetBlogDetailsQuery } from "../../features/blogApiSlice";
import {Helmet} from "react-helmet";
import {OurBlogs} from "../../Constants";
import {useTranslation} from "react-i18next";
import {Buffer} from "buffer";
import {useGetContentsQuery} from "../../features/contentsApiSlice";
import {useSelector} from "react-redux";
import {selectCurrentLanguage} from "../../features/langSlice";

const BlogDetails = () => {
    const { slug } = useParams()

    const idImage = Buffer.from('52').toString('base64')
    const [headerImage, setHeaderImage] = useState('')
    const [skip, setSkip] = useState(false);

    const [data, setData] = useState()
    const [blogs, setBlogs] = useState([])
    const [metaTitle, setMetaTitle] = useState('')
    const selectedLang = useSelector(selectCurrentLanguage)
    const [lang, setLang] = useState()
    const {t} = useTranslation()

    const { data: fetchDetails, 
        isLoading,
        isSuccess,
        isError,
        error,
        refetch } = useGetBlogDetailsQuery({slug: slug, lang: lang});


    const { data: fetchHeaderImage,
        isSuccess: isSuccessImage,
        refetch: refetchImage } = useGetContentsQuery({id: idImage, lang:lang, skip: skip});


    useEffect(() => {

        setLang(selectedLang==='us'?'en':selectedLang)

        refetch()

    },[refetch, lang, selectedLang]);

    useEffect(() => {

        if(isSuccessImage) {

            if(fetchHeaderImage.data.picture)
                setHeaderImage(`${process.env.REACT_APP_PUBLIC_URL}${fetchHeaderImage.data.picture}`)

        }

    },[fetchHeaderImage]);


    const { data: fetchBlogs, 
        isSuccess: isBlogsSuccess } = useGetBlogsQuery({lang: lang, page: 1, rows: 4, category: 'blog'});
        
    useEffect(() => {

        if(isSuccess) {

            setData(fetchDetails.data[0])
            setMetaTitle(fetchDetails.data[0].meta_title)
            console.log(fetchDetails.data[0])

        }

    },[fetchDetails]);


        
    useEffect(() => {

        const fn = () => {
            if(isBlogsSuccess && isSuccess && data && data.id) {

                const filteredRows = fetchBlogs.data.filter((item) => {
                    return item.id !== data.id
                })
    
                setBlogs(filteredRows)
    
            }
        }
        
        fn();


    },[fetchBlogs, data]);


    return (
    <>
        <Helmet>
            <title>{`Riise - ${data?.title}`}</title>
            <meta name="title" content={data?.meta_title} />
            <meta name="description" content={data?.meta_description} />
        </Helmet>
        <section className="steps section-padding !t-pb-2 !t-pt-5 !t-bg-gray-100">
            <div className="container">
                <nav aria-label="Page breadcrumb">
                    {/*<h2 className="title2">{t(OurBlogs)}</h2>*/}
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            <Link to={"/"} className="breadcrumb-item">
                                Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            <Link to={"/blogs"} className="breadcrumb-item">
                                {t(OurBlogs)}
                            </Link>
                        </li>
                        <li className="breadcrumb-item">{data?.title}</li>
                    </ol>
                </nav>
            </div>
        </section>
        <section className="blog-details section-padding">
      <div className="container">
        <div className="row gx-sm-5 gy-4">
          <div className="col-xl-8">

            <div className="blog-detail-inner">

              <img src={`${process.env.REACT_APP_PUBLIC_URL}${data?.picture}`} alt="" className="img-fluid mb-4" />
              
              <h3 className="title3">{data?.title}</h3>
              <span dangerouslySetInnerHTML={{ __html:data?.contents}} />
            </div>
          </div>
          <div className="col-xl-4">
            <div className="latest-posts">
              <h3 className="title2">Latest Posts</h3>

            {blogs.map((blog, index, {length}) => (
                <>
                    <a href={`${process.env.PUBLIC_URL}/blogs/${blog.slug}`}>
                    <div className="post-item" key={index}>
                        <img src={`${process.env.REACT_APP_PUBLIC_URL}${blog.picture}`} alt="" className="img-fluid"/>
                        
                        <div className="">
                            <p>{blog.title}</p>
                            <h5 dangerouslySetInnerHTML={{ __html: blog.contents.substring(0, 39)}} />
                        </div>
                    </div>
                    </a>
                    {length !== (index +1)&&(<hr/>)}
                
                </>
            ))}
              
              
              
              
            </div>
            {/*<div className="subscribe-box">*/}
            {/*  <h6>Discount</h6>*/}
            {/*  <h3 className="title2">subscribe and get x% <br />*/}
            {/*    on your first purchase</h3>*/}
            {/*    */}
            {/*  <div className="mb-0">*/}
            {/*    <input type="text" name="" id="" className="form-control" placeholder="enter email here.." aria-describedby="helpId" />*/}
            {/*    <button className="btn btn-custom-secondary w-100 mt-3">Subscribe Now</button>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    </section>
    </>
    );
}

export default BlogDetails;
