import React, {Fragment} from "react";
import { useNavigate } from 'react-router-dom';

const Customer = () => {
    const history = useNavigate();

    const onLogout = (e) => {
        e.preventDefault()
        localStorage.setItem("login", JSON.stringify(false));
        history(`${process.env.PUBLIC_URL}/login`);
    }

    return (
        <Fragment>
            Customer
            <button onClick={(e) => onLogout(e)}>Logout</button>
        </Fragment>
    );
}

export default Customer;