/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import React, { useImperativeHandle } from "react";
import moment from "moment/moment";
import Client from "./client";

import { Button, ImageButton, ImageCheckbox } from "./Components";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAnswersMutation } from "../../features/quizApiSlice";

const QuizSlide = React.forwardRef(
  (
    {
      className,
      currentSlide,
      slide,
      handleNext,
      slideValues,
      slideData,
      updateSlideNavigation,
      uniqid,
      slideNavigation,
      index,
      isLastSlide,
    },
    ref
  ) => {
    const history = useNavigate();
    let location = useLocation();

    const [answers, { isLoading }] = useAnswersMutation();

    const handleValueChange = (component, slideId, key, value) => {
      if (
        new Set([16, 23, 25, 26, 27, 29, 32, 33, 34, 36, 50, 51, 53]).has(
          slideId
        ) &&
        component.action
      ) {
        //digestion
        if (component.action === "unselect-all") {
          //get all the image-checkboxes as label element
          let labels = [];
          switch (slideId) {
            case 16:
              labels = document.querySelectorAll("#gImprovement label");
              break;
            case 23:
              labels = document.querySelectorAll("#gDigestion label");
              break;
            case 25:
              labels = document.querySelectorAll("#gSkin label");
              break;
            case 26:
              labels = document.querySelectorAll("#gHair label");
              break;
            case 27:
              labels = document.querySelectorAll("#gNails label");
              break;
            case 29:
              labels = document.querySelectorAll("#gHeartCond label");
              break;
            case 32:
              labels = document.querySelectorAll("#gMusclePain label");
              break;
            case 33:
              labels = document.querySelectorAll("#gAnyBoneProb label");
              break;
            case 34:
              labels = document.querySelectorAll("#gAboutBoneProb label");
              break;
            case 36:
              labels = document.querySelectorAll("#gDiet label");
              break;
            case 50:
              labels = document.querySelectorAll("#gExercise-type label");
              break;
            case 51:
              labels = document.querySelectorAll("#gAllergies label");
              break;
            case 53:
              labels = document.querySelectorAll("#gConditions label");
              break;
          }

          for (let i = 0; i < labels.length; i++) {
            if (labels[i].id !== key) {
              if (labels[i].classList.contains("checked")) {
                // If the class exists, remove it
                labels[i].classList.remove("checked");
                slideData(slideId, labels[i].id, false, labels[i].code);
              }
            }
          }
        }
      } else {
        let label = null;

        switch (slideId) {
          case 16:
            label = document
              .querySelector("#gImprovement")
              .querySelector(".unselect-all");
            break;
          case 23:
            label = document
              .querySelector("#gDigestion")
              .querySelector(".unselect-all");
            break;
          case 25:
            label = document
              .querySelector("#gSkin")
              .querySelector(".unselect-all");
            break;
          case 26:
            label = document
              .querySelector("#gHair")
              .querySelector(".unselect-all");
            break;
          case 27:
            label = document
              .querySelector("#gNails")
              .querySelector(".unselect-all");
            break;
          case 29:
            label = document
              .querySelector("#gHeartCond")
              .querySelector(".unselect-all");
            break;
          case 32:
            label = document
              .querySelector("#gMusclePain")
              .querySelector(".unselect-all");
            break;
          case 34:
            label = document
              .querySelector("#gAnyBoneProb")
              .querySelector(".unselect-all");
            break;
          case 33:
            label = document
              .querySelector("#gAboutBoneProb")
              .querySelector(".unselect-all");
            break;
          case 36:
            label = document
              .querySelector("#gDiet")
              .querySelector(".unselect-all");
            break;
          case 50:
            label = document
              .querySelector("#gExercise-type")
              .querySelector(".unselect-all");
            break;
          case 51:
            label = document
              .querySelector("#gAllergies")
              .querySelector(".unselect-all");
            break;
          case 53:
            label = document
              .querySelector("#gConditions")
              .querySelector(".unselect-all");
            break;
        }

        if (label) {
          if (label.classList.contains("checked")) {
            // If the class exists, remove it
            label.classList.remove("checked");

            //const input = label.querySelector('input')
            slideData(slideId, label.id, false, label.code);
          }
        }
      }

      slideData(slideId, key, value, component.answerCode);
    };

    const handleClick = (e, component) => {
      //e.preventDefault();

      if (component.nextScreen) {
        if (
          component.type === "checkbox" ||
          component.type === "img-checkbox"
        ) {
          updateSlideNavigation(component.nextScreen, e);
        } else {
          updateSlideNavigation(component.nextScreen, true);
        }
      }

      if (component.action) {
        if (component.action === "next") {
          if (validate(currentSlide)) {
            skipSlides(currentSlide, component);

            handleNext();
          }
        } else if (component.action === "summary") {
          //   const summary = document.getElementById('summary');
          //   let html = '';
          //   Object.entries(slideValues).forEach(([key, value]) => {
          //     html += key + ': ' + JSON.stringify(value) + '<br/>';
          //   });

          //   summary.innerHTML = html;

          const c = new Client();
          c.init(slideValues);

          // history(`/product-suggestion`, { state: c.get() });
          answers({
            quiz_id: 0,
            answers: c.get(),
            lang: "en",
            dataJson: slideValues,
            slideNavigation,
          })
            .then((res) => {
              console.log("Response => ", res);
              if (location.pathname.includes("/custom-quiz")) {
                history("/custom-quiz-success");
              } else {
                const { quiz_id, lastQuizId, oneOff, cancelledSub } = res.data;

                if (lastQuizId === 0 || oneOff || cancelledSub) {
                  history(`/product-suggestion`, {
                    state: { ...res.data, slideValues },
                  });
                } else {
                  history(`/dashboard/comparison`, {
                    state: { quiz_id, lastQuizId },
                  });
                }
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      }
    };

    // this is calling from QuizModule.jsx
    useImperativeHandle(ref, () => ({
      handlePrevious(previousActiveSlide) {
        //console.log(previousActiveSlide)
        if (previousActiveSlide === 36) {
          //updateSlideNavigation([37, 38, 39, 40, 41, 42], true)
          // slideData (36, 'diet-Dairy-free', false, 0);
          // slideData (36, 'diet-Gluten-free', false, 0);
          // slideData (36, 'diet-Other', false, 0);
          // slideData (36, 'diet-Vegan', false, 0);
          // slideData (36, 'diet-Vegetarian', false, 0);
          // slideData (36, 'diet-none', false, 0);
        }
      },
    }));

    const skipSlides = (currentSlide, component) => {
      if (currentSlide === 36) {
        if (slideValues[currentSlide]["diet-none"]) {
          updateSlideNavigation([37, 38, 39, 40, 41, 42], true);
        }
        if (slideValues[currentSlide]["diet-Vegan"]) {
          slideData(37, "eat-fish-per-week", "1", 154); // Less than once
          slideData(38, "eat-meat-per-week", "1", 157); // Less than once
          slideData(41, "dairy-per-day", "1", 167); // None

          updateSlideNavigation([38, 39, 42], false);
        }
        if (slideValues[currentSlide]["diet-Vegetarian"]) {
          slideData(37, "eat-fish-per-week", "1", 154); // Less than once

          updateSlideNavigation([38], false);
        }
        if (slideValues[currentSlide]["diet-Dairy-free"]) {
          slideData(41, "dairy-per-day", "1", 167); // None

          updateSlideNavigation([42], false);
        }
        if (slideValues[currentSlide]["diet-Gluten-free"]) {
          slideData(39, "eat-grains-per-day", "1", 160); // Less than once

          updateSlideNavigation([40], false);
        }
      }
    };

    const validate = (currentSlide) => {
      if (currentSlide === 0) {
        const regex = /^[A-Za-z\s-]+$/;
        const name = document.getElementById("name").value.trim();
        if (name === "") {
          return false;
        } else if (!regex.test(name)) {
          toast.error("Name should only contain alphabetic characters.");
          return false;
        }

        const agree = document.querySelector("#agree:checked");
        if (!agree) {
          return false;
        }
      } else if (currentSlide === 6) {
        const day = document.getElementById("day").value.trim();
        const month = document.getElementById("month").value.trim();
        const year = document.getElementById("year").value.trim();
        const date = moment(year + "-" + month + "-" + day); // "2016-10-19"

        if (
          day === "" ||
          month === "" ||
          year === "" ||
          isNaN(day) ||
          isNaN(month) ||
          isNaN(year) ||
          !date.isValid()
        ) {
          toast.error("Birthdate is invalid.");
          return false;
        }
      } else if (currentSlide === 7) {
        const weight = document.getElementById("weight").value.trim();
        const height = document.getElementById("height").value.trim();
        if (weight === "" || isNaN(weight) || height === "" || isNaN(height)) {
          return false;
        }
      } else if (currentSlide === 8) {
        const index = document.getElementById("country").selectedIndex;
        if (index < 1) {
          return false;
        }
      } else if (currentSlide === 11) {
        const email = document.getElementById("email").value.trim();
        if (email === "" || !email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
          toast.error("Please enter valid Email.");
          return false;
        }
      } else if (currentSlide === 36) {
        const labels = document.querySelectorAll("#gDiet label");

        let selected = false;
        for (let i = 0; i < labels.length; i++) {
          if (labels[i].classList.contains("checked")) {
            selected = true;
          }
        }

        if (!selected) {
          toast.error("Please select an option.");
          return false;
        }
      } else if (currentSlide === 54) {
        const labels = document.querySelectorAll("#gHearAboutUs label");

        let selected = false;
        for (let i = 0; i < labels.length; i++) {
          if (labels[i].classList.contains("checked")) {
            selected = true;
          }
        }

        if (!selected) {
          toast.error("Please select an option.");
          return false;
        }
      }
      return true;
    };

    const onCompleteQuiz = () => {
      const c = new Client();
      c.init(slideValues);

      answers({
        quiz_id: 0,
        answers: c.get(),
        lang: "en",
        dataJson: slideValues,
        slideNavigation,
      })
        .then((res) => {
          console.log("onCompleteQuiz => ", res);
          if (location.pathname.includes("/custom-quiz")) {
            history("/custom-quiz-success");
          } else {
            const { quiz_id, lastQuizId, oneOff, cancelledSub } = res.data;

            if (lastQuizId === 0 || oneOff || cancelledSub) {
              history(`/product-suggestion`, {
                state: { ...res.data, slideValues },
              });
            } else {
              history(`/dashboard/comparison`, {
                state: { quiz_id, lastQuizId },
              });
            }
          }
        })
        .catch((e) => {
          console.log("onCompleteQuiz =>", e);
        });
    };

    return (
      <div className={`${className} text-center`}>
        {isLastSlide && location.pathname.includes("/custom-quiz") ? (
          <div className="!t-h-[60vh] !t-flex t-flex-col t-items-center t-justify-center t-gap-2">
            <p className="t-m-0 t-text-white t-text-xl">
              Thanks for taking the test. Click below to complete it!
            </p>
            <div className="btn btn-custom-secondary">
              <button
                key={uniqid()}
                onClick={onCompleteQuiz}
                disabled={isLoading}
              >
                Complete your test
              </button>
            </div>
          </div>
        ) : (
          <>
            <h2 dangerouslySetInnerHTML={{ __html: slide[0].title }} />
            {slide.map((component, index) => {
              switch (component.type) {
                case "button":
                  return (
                    <Button
                      key={uniqid()}
                      component={component}
                      className={component.class}
                      // value={slideValues[currentSlide]?.value || ''}
                      onClick={(e) => {
                        e.preventDefault();
                        handleValueChange(
                          component,
                          currentSlide,
                          component?.name,
                          component?.value
                        );
                        handleClick(e, component);
                      }}
                    />
                  );
                case "img-button":
                  return (
                    <ImageButton
                      key={uniqid()}
                      component={component}
                      // value={slideValues[currentSlide]?.value || ''}
                      onClick={(e) => {
                        e.preventDefault();
                        handleValueChange(
                          component,
                          currentSlide,
                          component?.name,
                          component?.value
                        );
                        handleClick(e, component);
                      }}
                    />
                  );
                case "text":
                  return (
                    <input
                      key={uniqid()}
                      type="text"
                      className={component.class}
                      id={component.name}
                      placeholder={component.placeholder}
                      onChange={(e) =>
                        handleValueChange(
                          component,
                          currentSlide,
                          component.name,
                          e.target.value
                        )
                      }
                    />
                  );
                case "label":
                  return (
                    <label key={uniqid()} id={component.name}>
                      <span
                        dangerouslySetInnerHTML={{ __html: component.label }}
                      />
                    </label>
                  );
                // not being used
                case "radio":
                  return (
                    <>
                      <input
                        type="radio"
                        key={uniqid()}
                        value={component.value}
                        onChange={(e) => {
                          e.preventDefault();
                          handleValueChange(
                            component,
                            currentSlide,
                            component.name,
                            e.target.checked
                          );
                        }}
                      />
                      {component.text}
                    </>
                  );
                case "checkbox":
                  return (
                    <div className="screen-checkbox">
                      <label key={uniqid()}>
                        <input
                          type="checkbox"
                          id={component.name}
                          onChange={(e) => {
                            e.preventDefault();
                            handleValueChange(
                              component,
                              currentSlide,
                              component.name,
                              e.target.checked
                            );
                          }}
                        />
                        {component.label}
                      </label>
                    </div>
                  );
                case "select":
                  // const defaultVal = slideValues[component.code-1]
                  // console.log('defaultVal => ', defaultVal[component.name]['value'])
                  // console.log('defaultVal2 => ', defaultVal)
                  return (
                    <select
                      key={uniqid()}
                      id={component.name}
                      className={component.class}
                      // defaultValue={defaultVal}
                      onChange={(e) => {
                        e.preventDefault();
                        handleValueChange(
                          component,
                          currentSlide,
                          component.name,
                          e.target.value
                        );
                      }}
                    >
                      {component.value.split(",").map((option, i) => (
                        <option
                          key={uniqid()}
                          value={option.replace(/"/g, "").trim()}
                        >
                          {option.replace(/"/g, "").trim()}
                        </option>
                      ))}
                    </select>
                  );
                case "img-checkbox":
                  return (
                    <ImageCheckbox
                      key={uniqid()}
                      component={component}
                      onChange={(e) => {
                        //e.preventDefault();
                        //console.log(e)
                        let isChecked = true;
                        if (
                          slideValues[currentSlide] &&
                          slideValues[currentSlide][component.name]
                        ) {
                          isChecked =
                            !slideValues[currentSlide][component.name].value;
                        }

                        handleValueChange(
                          component,
                          currentSlide,
                          component.name,
                          isChecked
                        );
                        handleClick(e, component);
                      }}
                    />
                  );
                case "next":
                  return (
                    <>
                      <button
                        key={uniqid()}
                        className={component.class}
                        onClick={handleNext}
                      >
                        {component.label}
                      </button>
                    </>
                  );
                case "group":
                  return (
                    <div className={component.class} id={component.name}>
                      {component.controls.map((component, index) => {
                        switch (component.type) {
                          case "img-button":
                            return (
                              <ImageButton
                                key={uniqid()}
                                component={component}
                                onClick={(e) => {
                                  e.preventDefault();
                                  handleValueChange(
                                    component,
                                    currentSlide,
                                    component?.name,
                                    component?.value
                                  );
                                  handleClick(e, component);
                                }}
                              />
                            );
                          case "text":
                            return (
                              <input
                                key={uniqid()}
                                type="text"
                                className={component.class}
                                id={component.name}
                                placeholder={component.placeholder}
                                onChange={(e) =>
                                  handleValueChange(
                                    component,
                                    currentSlide,
                                    component.name,
                                    e.target.value
                                  )
                                }
                              />
                            );
                          case "label":
                            return (
                              <label key={uniqid()} id={component.name}>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: component.label,
                                  }}
                                />
                              </label>
                            );
                          case "img-checkbox":
                            return (
                              <ImageCheckbox
                                key={uniqid()}
                                slideId={slide[0].code}
                                className={component.class}
                                component={component}
                                onChange={(e) => {
                                  //e.preventDefault();

                                  let isChecked = true;
                                  if (
                                    slideValues[currentSlide] &&
                                    slideValues[currentSlide][component.name]
                                  ) {
                                    isChecked =
                                      !slideValues[currentSlide][component.name]
                                        .value;
                                  }

                                  handleValueChange(
                                    component,
                                    currentSlide,
                                    component.name,
                                    isChecked
                                  );

                                  handleClick(e, component);
                                }}
                              />
                            );

                          case "img-radio":
                            return (
                              <ImageCheckbox
                                key={uniqid()}
                                slideId={slide[0].code}
                                className={component.class}
                                component={component}
                                onChange={(e) => {
                                  //e.preventDefault();
                                  handleValueChange(
                                    component,
                                    currentSlide,
                                    component.name,
                                    e
                                  );

                                  handleClick(e, component);
                                }}
                              />
                            );
                        }
                      })}
                    </div>
                  );
                case "summary":
                  return (
                    <div className={component.className}>
                      <button
                        key={uniqid()}
                        onClick={handleNext}
                        disabled={isLoading}
                      >
                        {component.label}
                      </button>
                    </div>
                  );
                default:
                  return null; // Handle unknown component types or omit them
              }
            })}
          </>
        )}
      </div>
    );
  }
);

export default QuizSlide;
