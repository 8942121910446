import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerProfileAction } from '../../app/api/useDataManage/userActions';
import DashboardWrapper from './components/DashboardWrapper';
import Benefits from './Benefits';

function ReferralCode() {
  const dispatch = useDispatch();
  const [token] = useState(localStorage.getItem('customer_token'));
  const { customerProfile } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getCustomerProfileAction(token));
  }, [token, dispatch]);

  console.log("customerProfile => ", customerProfile)

  return (
    <DashboardWrapper title={'Referral Code'}>
      <div className='col-xl-8 col-lg-8 user-inner'>
        <div className='t-flex t-flex-col t-gap-8 !t-items-left'>
          <div className='t-flex t-flex-col t-gap-4'>
            <h4 className='t-font-bold'>Referral Code - 15% for your friends!</h4>
            {customerProfile && customerProfile?.referral_code ? (
              <h6>{customerProfile?.referral_code}</h6>
            ) : (
              <h6>No Referral Code available</h6>
            )}
          </div>
          <div className='t-flex t-flex-col t-gap-4'>
            <h4 className='t-font-bold'>Benefits</h4>
            <Benefits />
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
}

export default ReferralCode;
