import React, { useEffect, useState } from "react";
import { NewAccordion } from "./NewAccordion";

const EffectAccordion = ({ data }) => {
  const [picture1, setPicture1] = useState("");
  const [picture2, setPicture2] = useState("");
  const [picture3, setPicture3] = useState("");
  const [picture4, setPicture4] = useState("");
  const [picture5, setPicture5] = useState("");
  const [picture6, setPicture6] = useState("");

  useEffect(() => {
    if (data[16]) {
      setPicture1(`${process.env.REACT_APP_PUBLIC_URL}${data[16].picture}`);
      setPicture2(`${process.env.REACT_APP_PUBLIC_URL}${data[17].picture}`);
      setPicture3(`${process.env.REACT_APP_PUBLIC_URL}${data[18].picture}`);
      setPicture4(`${process.env.REACT_APP_PUBLIC_URL}${data[19].picture}`);
      setPicture5(`${process.env.REACT_APP_PUBLIC_URL}${data[20].picture}`);
      setPicture6(`${process.env.REACT_APP_PUBLIC_URL}${data[21].picture}`);
    }
  }, [data]);

  return (
    <div className="t-w-full md:t-w-[60%] t-mx-auto t-flex t-flex-col t-gap-4 ">
      <NewAccordion image={picture1 && picture1} heading={data[16]?.title}>
        {data[16] && (
          <span
            className="new-accordion"
            dangerouslySetInnerHTML={{ __html: data[16]?.contents }}
          />
        )}
      </NewAccordion>
      <NewAccordion image={picture2 && picture2} heading={data[17]?.title}>
        {data[17] && (
          <span
            className="new-accordion"
            dangerouslySetInnerHTML={{ __html: data[17]?.contents }}
          />
        )}
      </NewAccordion>
      <NewAccordion image={picture3 && picture3} heading={data[18]?.title}>
        {data[18] && (
          <span
            className="new-accordion"
            dangerouslySetInnerHTML={{ __html: data[18]?.contents }}
          />
        )}
      </NewAccordion>
      <NewAccordion image={picture4 && picture4} heading={data[19]?.title}>
        {data[19] && (
          <span
            className="new-accordion"
            dangerouslySetInnerHTML={{ __html: data[19]?.contents }}
          />
        )}
      </NewAccordion>
      <NewAccordion image={picture5 && picture5} heading={data[20]?.title}>
        {data[20] && (
          <span
            className="new-accordion"
            dangerouslySetInnerHTML={{ __html: data[20]?.contents }}
          />
        )}
      </NewAccordion>
      <NewAccordion image={picture6 && picture6} heading={data[21]?.title} borderBottom={false}>
        {data[21] && (
          <span
            className="new-accordion"
            dangerouslySetInnerHTML={{ __html: data[21]?.contents }}
          />
        )}
      </NewAccordion>
    </div>
  );
};

export default EffectAccordion;
