import React from "react";
import FocusAreaCard from "../Customer/components/FocusAreaCard";
import { Nutrient } from "../../Constants";
import { useTranslation } from "react-i18next";

const PersonalFormulaCard = ({
  userImg,
  bg,
  name,
  focusArea,
  desc,
  price,
  duration,
  focusAreaImages,
  focusAreaIcons,
  isProductPage,
  nutrients,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="t-flex-none t-w-[280px] t-rounded-xl t-p-4"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        fontFamily: "roboto",
      }}
    >
      <div className="t-bg-white t-flex t-flex-col t-gap-4 t-p-6 t-rounded-xl">
        <div className="t-flex t-items-center t-justify-center t-gap-6">
          <img src={userImg} alt="" className="t-h-16 t-w-16 t-rounded-full" />
          <h5 className="t-m-0 t-font-[900] t-font-roboto">{name}</h5>
        </div>
        {!isProductPage && (
          <h5
            className="t-m-0 t-text-base t-text-center t-font-[400] t-text-gray-500"
            style={{ fontFamily: "Roboto" }}
            dangerouslySetInnerHTML={{ __html: desc }}
          ></h5>
        )}
        <div className="t-flex t-flex-col t-items-center t-justify-center t-gap-2">
          {focusArea &&
            focusArea.map((item) => {
              const focusImage = focusAreaImages?.find((i) => i?.id === item);
              const focusIcon = focusAreaIcons?.find(
                (i) => i?.name === focusImage?.name
              );
              return (
                <FocusAreaCard
                  icon={focusIcon?.url}
                  bg={focusImage?.url}
                  name={focusImage?.name || ""}
                  key={"focus-area-item-" + item}
                />
              );
            })}
        </div>
        {isProductPage ? (
          <p className="!t-m-0 t-text-base t-text-center">
            {nutrients} {t(Nutrient)}
          </p>
        ) : (
          <div className="t-flex t-flex-col t-gap-0 t-items-center t-justify-center">
            <h5
              className="!t-m-0 t-text-base t-font-[900] t-text-black "
              style={{ fontFamily: "roboto" }}
            >
              {price}
            </h5>
            <p className="!t-m-0 t-text-base t-text-gray-500">({duration})</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PersonalFormulaCard;
