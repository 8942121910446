import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TopNavBar from './TopNavBar';
import { selectCurrentLanguage } from '../../features/langSlice';
import { Link } from 'react-router-dom';

function QuizComparisonMessage() {
  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState();

  useEffect(() => {
    const lng = localStorage.getItem('lang');
    setLang(lng === 'us' ? 'en' : lng);
  }, []);

  useEffect(() => {
    setLang(selectedLang === 'us' ? 'en' : selectedLang);
  }, [selectedLang, lang]);

  return (
    <Fragment>
      <div>
        <main>
          <section className='t-pt-6'>
            <div className='container'>
              <TopNavBar title={'Retake'} />
            </div>
          </section>
          <section className='blog-details section-padding'>
            <div className='container'>
              <div className='row gx-sm-5 gy-4'>
                <div className='col-xl-12 col-lg-12 user-inner t-text-center'>
                  <div className='blog-detail-inner t-py-10 md:t-py-20'>
                    <h3>Thank you!</h3>
                    <p>
                      Click here to go back to the{' '}
                      <Link to={'/dashboard'} style={{ color: 'blue' }}>
                        Customer Area
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </Fragment>
  );
}

export default QuizComparisonMessage;
