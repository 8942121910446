import React, {Fragment} from "react";
import EmptyHeader from "./Header/EmptyHeader";
import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";

const EmptyLayout = ({ children, classNames, ...rest }) => {

    return (
        <Fragment>
            <div>
                <EmptyHeader />
                <Outlet />
            </div>
            <ToastContainer />
        </Fragment>
    );
}

export default EmptyLayout;