import React, { Fragment, useState, useEffect, useContext } from 'react';
import { Col, Container, Form, FormGroup, Button, Input, Label, Row, h4, p } from "reactstrap";
import { useLocation } from "react-router-dom";

import { logo } from '../Data/Images/Home';
import { ToastContainer, toast } from "react-toastify";


import '../assets/scss/pages/_login.css';

const SignUpComplete = () => {
    const { state } = useLocation();
  return (
    <Fragment>
    <Container fluid={true} className='login-section section-padding pt-0'>
      <div className="container d-flex align-items-center justify-content-center vh-100">
        <div className="col-6">
          <div className="mlogo mb-5">
            <a href="">
              <img src={logo} alt="" width={120} />
            </a>
          </div>
          <h3 className="text-center text-white mb-5">{state}</h3>
          
          <div className="row mt-5">
              <div className="col-6">
                <a href={`${process.env.PUBLIC_URL}/login`}>Go to Login</a>
              </div>
          </div>
        </div>
      </div>
    </Container>
    <ToastContainer />
    </Fragment>
  );
};

export default SignUpComplete;
