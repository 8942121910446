import React from "react";
import BannerImage from "../../assets/partner/PartnerBanner.png";
import { Link } from "react-router-dom";

const HowItWorks = [
  "Take a 5 minutes health quiz",
  "Share your blood test results",
  "Get your personal recipe directly at home! ",
];

const Banner = () => {
  return (
    <div className="t-flex t-flex-col t-gap-4 t-items-center t-justify-center">
      <div className="t-flex t-flex-col t-gap-4 t-items-center t-justify-center t-px-4">
        <h4 className="t-font-bolder t-text-center">
          Get your personalized supplements from our partner riise!
        </h4>
        <p className="t-m-0 t-text-center t-text-base">
          Fully personalized all-in-one, daily doses with up to 25 ingredients.
          Only what you need, starting from your deficiencies.
        </p>
        <div className="t-flex t-flex-col t-gap-2 t-bg-gray-100 t-p-4 t-rounded-md">
          <h5 className="t-text-center">How it works</h5>
          {HowItWorks.map((item, i) => (
            <div
              className="t-flex t-items-center t-gap-2"
              key={"how-it-works-" + i}
            >
              <p className="t-m-0 t-text-white t-bg-secondary t-w-6 t-h-6 t-rounded-full t-flex-none t-flex t-items-center t-justify-center t-font-bold t-text-base">
                {i + 1}
              </p>
              <p className="t-m-0 t-w-full t-text-center t-text-base">{item}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="t-flex t-flex-col t-items-center t-w-full ">
        <Link to="/custom-quiz?partner_id=carrotcare" className="t-z-10">
          <button className="btn !t-w-fit text-capitalize !t-bg-primary t-text-black !t-rounded-md">
            Start now
          </button>
        </Link>

        <img src={BannerImage} alt="" className="-t-mt-16" />
      </div>
    </div>
  );
};

export default Banner;
