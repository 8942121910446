import React, { Fragment, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Link, Outlet, useLocation, useSearchParams } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ReactGA from "react-ga4";
import MetaPixel from "../utils/meta/MetaPixel";
import { cart } from "../Data/Images/Home";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLanguage } from "../features/langSlice";

const EXCLUDE_LAYOUT_ROUTES = ["/product-suggestion"];
const EXCLUDE_CART_BTN_ROUTES = [
  "/product-suggestion",
  "/customer",
  "/quiz",
  "/cart",
  "/cart/ordercomplete",
  "/custom-formula",
];

const AppLayout = ({ children, classNames, ...rest }) => {
  const location = useLocation();
  const token = localStorage.getItem("customer_token");
  const quiz_id = localStorage.getItem("quiz_id");
  const [searchParams] = useSearchParams();
  const defaultLanguage = searchParams.get("lang");

  useEffect(() => {
    ReactGA.send("pageview");
  }, [location]);

  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!defaultLanguage) return;
    localStorage.setItem("lang", defaultLanguage);
    i18n.changeLanguage(defaultLanguage);
    dispatch(setLanguage({ lng: defaultLanguage }));
  }, [defaultLanguage, dispatch, i18n]);

  return (
    <Fragment>
      {/* Conditionally render MetaPixel only when needed, or wrap it around content if it injects global scripts */}
      <MetaPixel />
      <div>
        {!EXCLUDE_LAYOUT_ROUTES.includes(location.pathname) && <Header />}
        <Outlet />
        {!EXCLUDE_LAYOUT_ROUTES.includes(location.pathname) && <Footer />}
        {quiz_id &&
          !token &&
          !EXCLUDE_CART_BTN_ROUTES.includes(location.pathname) && (
            <div className="sm:t-hidden t-w-15 t-h-15 t-fixed t-bottom-5 t-right-5 t-z-50 t-bg-secondary t-rounded-full t-p-2 t-flex t-items-center t-justify-center">
              <div className="t-relative">
                <Link className="cart" to="/product-suggestion">
                  <img
                    src={cart}
                    alt=""
                    className="img-fluid -t-ml-1"
                    width={35}
                  />
                </Link>
                <div className="!t-h-3 !t-w-3 !t-rounded-full t-bg-red-500 t-absolute -t-right-1 t-top-0" />
              </div>
            </div>
          )}
      </div>
      <ToastContainer />
    </Fragment>
  );
};
export default AppLayout;
