import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLanguage } from '../../features/langSlice';
import { useGetContentsQuery } from '../../features/contentsApiSlice';
import { Buffer } from 'buffer';
import DashboardWrapper from './components/DashboardWrapper';

const CustomerService = () => {
  const id = Buffer.from('59').toString('base64');
  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState();
  const [data, setData] = useState([]);

  const [skip] = useState(false);
  const {
    data: fetchContents,
    isSuccess,
    refetch,
  } = useGetContentsQuery({ id: id, lang: lang, skip: skip });

  useEffect(() => {
    setLang(selectedLang === 'us' ? 'en' : selectedLang);

    refetch();
  }, [refetch, lang, selectedLang]);

  useEffect(() => {
    if (isSuccess) {
      setData(fetchContents.data);
    }
  }, [fetchContents, isSuccess]);

  return (
    <DashboardWrapper title={data.title}>
      <div className='t-flex t-flex-col t-gap-8 !t-items-left'>
        <div className='t-flex t-flex-col t-gap-4'>
          <h4 className='t-font-bold'>Customer Service</h4>
          <div className='blog-detail-inner !t-text-base'>
            <span dangerouslySetInnerHTML={{ __html: data.contents }} />
          </div>
        </div>
      </div>
    </DashboardWrapper>
  );
};

export default CustomerService;
