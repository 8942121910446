import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentLanguage } from '../features/langSlice';
import { useGetContentsQuery } from '../features/contentsApiSlice';
import { ContactUs_ } from '../Constants/index';

import { Buffer } from 'buffer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
  const { t } = useTranslation();
  const id = Buffer.from('47').toString('base64');
  const [data, setData] = useState([]);
  const [lang, setLang] = useState();
  const selectedLang = useSelector(selectCurrentLanguage);
  const [skip] = useState(false);

  const { data: fetchContents, isSuccess: isSuccessContents } =
    useGetContentsQuery({ id: id, lang: lang, skip: skip });

  useEffect(() => {
    setLang(selectedLang === 'us' ? 'en' : selectedLang);
  }, [lang, selectedLang]);

  useEffect(() => {
    if (isSuccessContents) {
      setData(fetchContents.data);
    }
  }, [fetchContents, isSuccessContents]);

  return (
    <>
      <section className='t-flex t-flex-col'>
        <div className='t-pt-6'>
          <div className='container '>
            <nav aria-label='Page breadcrumb'>
              <h2 className='title2 !t-text-[24px]'>{t(ContactUs_)}</h2>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item active' aria-current='page'>
                  <Link to={'/'} className='breadcrumb-item  !t-text-gray-500'>
                    Home
                  </Link>
                </li>
                <li className='breadcrumb-item  !t-text-gray-500'>
                  {t(ContactUs_)}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      <section className='contact-section section-padding'>
        <div className='container'>
          <span dangerouslySetInnerHTML={{ __html: data.contents }} />
        </div>
      </section>
    </>
  );
};

export default ContactUs;
