import React, { useState, useEffect } from "react";
import { arrow_down } from "../../Data/Images/Home";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setLanguage } from "../../features/langSlice";
import { useNavigate, useSearchParams } from "react-router-dom";

const Language = ({ isSidebar }) => {
  const [isLangMenuOpen, setIsLangMenuOpen] = useState(false);
  const [selected, setSelected] = useState(
    localStorage.getItem("lang") || "en"
  );
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const defaultLanguage = searchParams.get("lang");

  useEffect(() => {
    dispatch(setLanguage({ lng: selected }));
  }, [selected]);

  useEffect(() => {
    if (!defaultLanguage) return;
    setSelected(defaultLanguage);
  }, [defaultLanguage]);

  const updateUrlWithLang = (lng) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("lang", lng);
    navigate({ search: newSearchParams.toString() });
  };

  const changeLanguage = (e, lng) => {
    e.preventDefault();

    localStorage.setItem("lang", lng);
    i18n.changeLanguage(lng);
    updateUrlWithLang(lng);

    setSelected(lng);

    dispatch(setLanguage({ lng }));

    setIsLangMenuOpen(!isLangMenuOpen);
  };
  const toggleLangMenu = (e) => {
    e.preventDefault();
    setIsLangMenuOpen(!isLangMenuOpen);
  };

  return (
    <>
      <li className="nav-item dropdown m-lang t-list-none">
        <a
          className={`login ${isLangMenuOpen ? "show" : ""}`}
          href="#"
          id="dropdownId"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded={`${isLangMenuOpen ? "true" : "false"}`}
          onClick={(e) => toggleLangMenu(e)}
        >
          <span
            style={{
              textTransform: "uppercase",
              color: isSidebar ? "#003b4d" : "#f9e04f",
            }}
          >{`${selected === "us" ? "en" : selected}`}</span>{" "}
          <i
            className={`fas fa-sort-down ${
              isSidebar ? "t-text-[#003b4d]" : "t-text-[#f9e04f]"
            } `}
          ></i>
        </a>
        <div
          className={`dropdown-menu !t-right-0 ${
            isSidebar ? "!t-bottom-full !t-left-0" : ""
          } ${isLangMenuOpen ? "show" : ""}`}
          aria-labelledby="dropdownId"
          {...(isLangMenuOpen ? "data-bs-popper='none'" : "")}
        >
          <a
            className="dropdown-item"
            href="#"
            onClick={(e) => changeLanguage(e, "us")}
          >
            <i className="flag-icon flag-icon-us"></i> English
          </a>
          <a
            className="dropdown-item"
            href="#"
            onClick={(e) => changeLanguage(e, "de")}
          >
            <i className="flag-icon flag-icon-de"></i> German
          </a>
        </div>
      </li>
    </>
  );
};

export default Language;
