import React, { useMemo, useState } from "react";
import { Icon } from "../../elements/icon";

const MICRO_INGREDIENT_IDS = [25, 18, 20, 26, 28, 30];

const TableRow = ({
  item,
  i,
  onRemoveQuantity,
  ingredientData,
  onAddQuantity,
  prices,
  removeIngredientFromBin,
  euNrv,
  onInputChange,
  isCustomFormula = false,
}) => {
  const [desc, setDesc] = useState(false);
  const maxQuantity = useMemo(
    () =>
      isCustomFormula
        ? MICRO_INGREDIENT_IDS.includes(item?.ingredient_id)
          ? parseFloat(ingredientData?.limit_professionals) * 1000
          : parseFloat(ingredientData?.limit_professionals)
        : MICRO_INGREDIENT_IDS.includes(item?.ingredient_id)
        ? parseFloat(ingredientData?.bfr_2021) * 1000
        : parseFloat(ingredientData?.bfr_2021),
    [
      ingredientData?.bfr_2021,
      item?.ingredient_id,
      isCustomFormula,
      ingredientData?.limit_professionals,
    ]
  );

  const maxQuantityLimit = useMemo(
    () =>
      isCustomFormula
        ? parseFloat(ingredientData?.limit_professionals)
        : parseFloat(ingredientData?.bfr_2021),
    [
      isCustomFormula,
      ingredientData?.limit_professionals,
      ingredientData?.bfr_2021,
    ]
  );

  return (
    <>
      <tr className="t-relative ">
        <td className="">
          <p className="t-m-0 t-mt-2 t-text-[12px] md:t-text-sm t-font-bold">
            {item.ingredient}
          </p>
          <Icon
            name="infoFilled"
            color="#005d67"
            size="20"
            className="t-mt-[10px] md:t-mt-[12px]"
            onClick={() => setDesc(!desc)}
          />
        </td>
        <td>
          <div className="t-text-[10px] md:t-text-sm t-flex t-flex-col t-items-center t-gap-1">
            <div className="t-flex t-flex-row t-items-center t-gap-2">
              <Icon
                name="minus"
                size="18"
                color="#003b4d"
                onClick={() => onRemoveQuantity(item, i, isCustomFormula)}
              />
              <div className="t-flex t-items-center t-items-between t-border-2 t-pr-2 t-rounded-sm">
                <input
                  type="number"
                  step="any"
                  lang="en-US"
                  max={maxQuantity}
                  value={
                    MICRO_INGREDIENT_IDS.includes(item?.ingredient_id)
                      ? (item?.daily_dose * 1000).toString().replace(",", ".")
                      : item?.daily_dose.toString().replace(",", ".")
                  }
                  className="t-w-[40px] md:t-w-[55px] t-px-1 t-py-2 t-h-full t-outline-none !t-text-[11px] md:!t-text-sm !t-font-bold"
                  onKeyDown={(e) => {
                    if (["-", "+", "e", ","].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    onInputChange(
                      e,
                      i,
                      item,
                      euNrv,
                      ingredientData,
                      isCustomFormula
                    )
                  }
                />
                <p className="!t-text-[11px] md:!t-text-sm !t-font-bold t-text-gray-500 t-m-0">
                  {MICRO_INGREDIENT_IDS.includes(item.ingredient_id)
                    ? "µg"
                    : "mg"}
                </p>
              </div>
              <Icon
                name="plus"
                size="18"
                color="#003b4d"
                onClick={() => onAddQuantity(item, i, isCustomFormula)}
              />
            </div>
            <p className="t-text-gray-500 t-text-[11px] t-font-bold !t-m-0">
              (0 - {maxQuantity}
              {MICRO_INGREDIENT_IDS.includes(item.ingredient_id) ? "µg" : "mg"})
            </p>
            {parseFloat(item?.daily_dose) > maxQuantityLimit && (
              <p className="t-text-red-500 t-text-center">
                Value above threshold (max {maxQuantity})
              </p>
            )}
            {parseFloat(item?.daily_dose) < 0 && (
              <p className="t-text-red-500 t-text-center">
                Value below threshold (min 0)
              </p>
            )}
          </div>
        </td>
        <td className="t-text-[11px] md:t-text-sm t-text-center !t-pt-4">
          {parseFloat(ingredientData?.eu_nrv) <= 0
            ? "*"
            : `${item.per_eu_nrv}%`}
        </td>
        <td className="t-text-[11px] md:t-text-sm t-text-center !t-pt-4">
          {prices[item.ingredient] ?? 0}
        </td>
        <td>
          <Icon
            name="circleX"
            className="t-mt-2"
            size="16"
            color="gray"
            onClick={() => removeIngredientFromBin(item)}
          />
        </td>
      </tr>
      {desc && (
        <tr className="">
          <td
            colSpan="5"
            className="t-text-xs md:t-text-sm t-text-justify t-py-2 !t-border-bottom-[0px]"
          >
            {item.description}
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;
