import React, { useState } from 'react';
import { Icon } from './icon';

const Accordion = ({ heading, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className='t-flex t-flex-col t-gap-1'>
      <div
        className='t-flex t-flex-row t-gap-4 t-items-center t-justify-between t-cursor-pointer'
        onClick={() => setOpen(!open)}
      >
        <p className='t-text-sm md:t-text-base t-text-gray-900 t-mb-0 !t-leading-6 t-font-bold'>
          {heading}
        </p>
        <Icon name={open ? 'chevronUp' : 'chevronDown'} color='#667085' size='20' />
      </div>
      {open ? <div className='t-w-full'>{children}</div> : null}
    </div>
  );
};

const AccordionInfo = ({ heading, children }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className='t-flex t-flex-col t-gap-1'>
      <div
        className='t-flex t-flex-col t-gap-0 t-items-start  t-cursor-pointer'
        onClick={() => setOpen(!open)}
      >
        <p className='t-text-xs md:t-text-base t-text-gray-900 t-mb-0 t-mt-1 !t-leading-6 t-font-bold'>
          {heading}
        </p>
        <Icon name="infoFilled" color='#005d67' size='20' className='t-mt-[10px] md:t-mt-[12px]' />
      </div>
      {open ? <div className='t-w-full'>{children}</div> : null}
    </div>
  );
};

export { Accordion,AccordionInfo };
