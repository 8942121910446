import { apiSlice } from "../app/api/apiSlice";

export const BlogApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getBlogs: builder.query({
            query: (arg) => {
                const {lang, page, rows, category} = arg;
                return {url: `blog/listweb?lang=${lang}&page=${page}&rows=${rows}&category=${category}`}
            },
            validateStatus: (response, result) => {
                console.log(result)
                return response.status === 200 && !result.isError
            },
        }),
        getBlogDetails: builder.query({
            query: (arg) => {
                const {slug, lang} = arg
                return {url: `blog/details?slug=${slug}&lang=${lang}`}
            },
            validateStatus: (response, result) => {
                console.log(result)
                return response.status === 200 && !result.isError
            },
        }),
    })
})

export const {
    useGetBlogsQuery,
    useGetBlogDetailsQuery,
} = BlogApiSlice