import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logo } from "../../Data/Images/Home";
import { useCustomerIsNewMutation } from "../../features/auth/authApiSlice";
import Language from "../../Layout/Header/Language";

const OrderComplete = () => {
//   const [customerIsNew, { isLoading }] = useCustomerIsNewMutation();

//   const [status, setStatus] = useState("");

//   useEffect(() => {
//     const fn = async () => {
//       const res = await customerIsNew();
//       setStatus(res?.data?.message);
//     };

//     fn();
//   }, []);

  return (
    <>
      <div className="t-sticky t-top-0 t-z-10 t-w-full t-bg-secondary t-flex t-items-center t-justify-center t-py-2">
        <div className="container t-flex t-items-center t-justify-center">
          <img src={logo} alt="" width={60} />
          <div className="t-ml-auto">
            <Language />
          </div>
        </div>
      </div>
      <div
        className="t-w-full t-h-[80vh] product-suggestion-section section-padding t-flex t-flex-col t-items-center t-justify-center t-gap-4"
      >
        <h2 className="text-center">Order successfully completed</h2>

        <h4 className="text-center">
          Check your email for completing your profile
        </h4>
        <a href={"/"} className="!t-text-sm !t-text-capitalize btn-custom-quaternary !t-px-8 t-py-2 !t-bg-secondary disabled:!t-bg-quaternary hover:!t-text-white">
          <h6 className="text-center t-m-0">Go to home</h6>
        </a>
      </div>
    </>
  );
};

export default OrderComplete;
