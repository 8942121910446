import React from 'react';
import Inquiry from '../Inquiry';
import DashboardWrapper from './components/DashboardWrapper';

const CustomerInquiry = () => {
  return (
    <DashboardWrapper title={'Inquiry'}>
      <div className='col-xl-8 col-lg-8 user-inner'>
        <Inquiry />
      </div>
    </DashboardWrapper>
  );
};

export default CustomerInquiry;
