import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';

import translationEN from './assets/i18n/en.json';
import translationDE from './assets/i18n/de.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: translationEN,
      },
      de: {
        translation: translationDE,
      },
    },
    lng: 'en', 
    fallbackLng: 'en', // Fallback language
    debug: false,

    //keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // React already safely escapes strings
    },
  });


