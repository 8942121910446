import React, {Fragment, useEffect, useRef, useState} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { cartActions } from "../../app/api/cart/cartReducer";

import {
    CheckOut_,
    RecipientDetails_,
    CompanyName,
    ShippingDetails,
    CountryRegion,
    StreetAddress,
    Apartment,
    State,
    Zipcode,
    FirstName,
    LastName
} from "../../Constants";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import {
    getAddressAction,
    getCustomerProfileAction, getCustomerProfileFirstAction,
    getPaymentGateWayInfo, getProfileActions, getUserShiipingInfo,
} from "../../app/api/useDataManage/userActions";
import stripeImage from "../../assets/images/stripe.jpg";
import payPalImage from "../../assets/images/paypal.jpg";
import { loadStripe } from "@stripe/stripe-js";


import PaymentForm from "./PaymentForm";


import { Elements } from "@stripe/react-stripe-js";
import PayPalPayment from "./PayPalPayment";
import {
    createOrderActions,
    createUserReferalCodeAction,
    updateUserReferalCodeAction,
    userUsedDiscountAction,
} from "../../app/api/cart/cartActions";
import {useTranslation} from "react-i18next";
import {RecipientDetails} from "../../Constants";
import PayPalSubscriptionButton from "./PayPalSubscriptionButton";

// const stripePromise = loadStripe(`${process.env.Stripe_Publishable_key}`);
// const stripePromise = loadStripe(
//   "pk_test_51OI1fmA4JRt6oDMlQ8YWQeIj8yyrTxkN5a1dAKYVPwXWcIOm5I5dXKsEWSF6zdzXoFOMwoSXFZlBrhIfFnPvqft800rNpSRs0q"
// );
const url = `${process.env.REACT_APP_BASE_URL}`;
const Checkout = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const dispatch = useDispatch();

    const [paymentError, setPaymentError] = useState("");
    const [orderSucess, setOrderSucess] = useState(false);
    const [errorMessage, setErorMessage] = useState("");
    const [stripeSelected, setStripe] = useState(false);
    const [paypalSelected, setPayPalSelected] = useState(false);
    const [token, setToken] = useState(localStorage.getItem("customer_token"));
    const [quizId, setQuizId] = useState(localStorage.getItem("quiz_id"));
    const [cartData, setCartData] = useState();
    const {t} = useTranslation()
    const [stripePromise, setStripePromise] = useState();
    const [payment_subId, setPayment_subId] = useState("");

    console.log(cartData);
    const { cart, subscription, message, orderId } = useSelector(
        (state) => state.cart
    );
    const { user, address, shippingAddress, customerProfile, gatwayInfo } =
        useSelector((state) => state.user);

    //console.log(customerProfile?.first_name);
    console.log(shippingAddress)
    console.log(gatwayInfo)
    console.log(user.email)

    const stripeData = gatwayInfo?.filter((item) => item.company === "stripe");
    const paypalData = gatwayInfo?.filter((item) => item.company === "paypal");


    const [toggle, settoggle] = useState(false);

    useEffect(() => {
        const stripeData = gatwayInfo.filter((item) => item.company === "stripe");
        if (stripeData?.length !== 0) {
            const stripePromise = loadStripe(stripeData[0]?.publishable_key || "");
            setStripePromise(stripePromise);
        }
    }, [gatwayInfo]);

    useEffect(() => {
        dispatch(getCustomerProfileFirstAction({quiz_id: quizId}))
        //dispatch(getAddressAction({user_id: customerProfile?.user_id}));
        dispatch(getUserShiipingInfo({ email: user.email }));
        dispatch(getProfileActions(token));
        dispatch(getPaymentGateWayInfo());
    }, [dispatch, token, user.email]);

    useEffect(() => {
        if(orderSucess === true) {
            navigate('/cart/ordercomplete')
        }
    }, [orderSucess]);

    const [shippingInfo, setShippingInfo] = useState({
        firstName: customerProfile?.first_name ? customerProfile?.first_name : "",
        lastName: customerProfile?.last_name ? customerProfile?.last_name : "",
        companyName: "",
        country: customerProfile?.country ? customerProfile?.country : "",
        address: "",
        appartment: "",
        state: "",
        zipCode: "",
        phoneNo: customerProfile?.phone ? customerProfile?.phone : "",
        email: user.email ? user.email : "",
    });

    const orderData = {
        productId: 1,
        productName: cart?.name,
        totalPrice: cartData?.cart.total,
        customer_quiz_id: cart?.customer_quiz_id,
        orderstatusId: 1,
        userId: user?.id,
        shippingInfoId: 0,
        customer_id: ""
    };
    console.log(orderData);
    const shippingChangeHandler = (e) => {
        setShippingInfo({ ...shippingInfo, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        const cartData = location.state;
        setCartData(cartData);
    }, [location]);

    useEffect(() => {
        const subscriptionJSON = localStorage.getItem("subscription");
        const productJSON = localStorage.getItem("product");
        const storedSubscription = JSON.parse(subscriptionJSON);
        dispatch(cartActions.getSubscription(storedSubscription));
        const storedProduct = JSON.parse(productJSON);
        dispatch(cartActions.getCartItem(storedProduct));
    }, [dispatch]);

    useEffect(() => {
        if (shippingAddress != null || shippingAddress !== undefined) {
            setShippingInfo({
                ...shippingInfo,
                firstName: customerProfile?.first_name
                    ? customerProfile?.first_name
                    : shippingAddress?.firstName,
                lastName: customerProfile?.last_name
                    ? customerProfile?.last_name
                    : shippingAddress?.lastName,
                address: shippingAddress?.address,
                country: shippingAddress?.country,
                appartment: shippingAddress?.appartment,
                state: shippingAddress?.state,
                zipCode: shippingAddress?.zipCode,
                companyName: shippingAddress?.companyName,
                phoneNo: customerProfile?.phone
                    ? customerProfile?.phone
                    : shippingAddress?.phoneNo,
                email: user?.email ? user?.email : "",
            });
        }
    }, [shippingAddress, customerProfile]);

    useEffect(() => {
        const subhandler = async () => {
            if (message === "Order Successful") {
                if (subscription) {
                    const subData = {
                        subscription: subscription,
                        orderId: orderId,
                        payment_subId: payment_subId,
                    };
                    await axios
                        .post(
                            `${process.env.REACT_APP_BASE_URL}customer/subscription`,
                            subData,
                            {
                                headers: { Authorization: `Bearer ${token}` },
                            }
                        )
                        .then(async () => {
                            localStorage.removeItem("product");
                            localStorage.removeItem("subscription");
                            handlePaymentStatus("success");
                            referalPaymentHandler(orderId);
                            setOrderSucess(true);
                        })
                        .catch((err) =>
                            toast.error(err.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            })
                        );
                }
                // else {
                //     localStorage.removeItem("product");
                //     localStorage.removeItem("subscription");
                //     handlePaymentStatus("success");
                //     setOrderSucess(true);
                // }
            } else if (message) {
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        };
        subhandler();
    }, [message]);

    const pressHandler = async () => {
        const isShippingInfoValid =
            shippingInfo.firstName?.length >= 3 &&
            shippingInfo.lastName?.length >= 3 &&
            //shippingInfo.companyName.length >= 4 &&
            shippingInfo.country?.length >= 3 &&
            shippingInfo.address?.length >= 6 &&
            //shippingInfo.appartment.length >= 3 &&
            shippingInfo.state?.length >= 3 &&
            shippingInfo.zipCode?.length >= 4 &&
            shippingInfo.phoneNo?.length >= 10 &&
            shippingInfo.email?.length >= 3;

        if (!isShippingInfoValid) {
            setErorMessage("Invalid shipping information. Please check all fields.");
            return;
        }
        if (isShippingInfoValid) {
            settoggle(true);
        }
    };

    // const checkOutHandler = async (paymentSuccess) => {
    //     let paymentMethod = "";
    //     if (paymentSuccess.transactionId && stripeSelected) {
    //         paymentMethod = "stripe";
    //     }
    //     if (paymentSuccess.transactionId && paypalSelected) {
    //         paymentMethod = "paypal";
    //     }
    //
    //     if (paymentSuccess.error) {
    //         console.log("error", paymentSuccess.error);
    //         setPaymentError(paymentSuccess.error);
    //         return;
    //     }
    //
    //     const data = {
    //         shipping: shippingInfo,
    //         orderData: orderData,
    //         paymentMethod: paymentMethod,
    //         transactionId: paymentSuccess.transactionId,
    //     };
    //
    //     console.log("data sending for order creation", data);
    //     // if (stripeSelected && paymentSuccess) {
    //     //   data.paymentMethod = "stripe";
    //     //   console.log(data);
    //     // }
    //     // if (paypalSelected && paymentSuccess) {
    //     //   data.paymentMethod = "paypal";
    //     //   console.log(data);
    //     // }
    //
    //     dispatch(createOrderActions(data));
    //     console.log(message);
    //
    //     console.log(paymentSuccess);
    // };

    const checkOutHandler = async (paymentSuccess) => {
        let paymentMethod = "";
        if (paymentSuccess.transactionId && stripeSelected) {
            paymentMethod = "stripe";
        }
        if (paymentSuccess.transactionId && paypalSelected) {
            paymentMethod = "paypal";
        }

        if (paymentSuccess.error) {
            console.log("error", paymentSuccess.error);
            setPaymentError(paymentSuccess.error);
            return;
        }

        console.log(paymentSuccess)
        orderData.customer_id = paymentSuccess.customerId;
        setPayment_subId(paymentSuccess?.subscriptionId);
        const data = {
            shipping: shippingInfo,
            orderData: orderData,
            paymentMethod: paymentMethod,
            transactionId: paymentSuccess.transactionId,
            customer_id: paymentSuccess.customerId,
            subscriptionId: paymentSuccess.subscriptionId,
            paymentJson: JSON.stringify(paymentSuccess.paymentJson),
        };


        dispatch(createOrderActions(data));
        console.log(message);

        console.log(paymentSuccess);
    };
    
    const handlePaymentStatus = (status) => {
        if (status === "success") {
            toast.success(status, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        if (status === "Payment failed") {
            toast.error(status, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
        if (status === "PayPal payment success") {
            toast.success(status, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };


    const referalPaymentHandler = async (orderId) => {
        try {
            if (cartData.discountData.referralCode === true) {
                console.log("calllled");

                const referalData = {
                    customer_referrer_id: cartData.discountData.customer_referrer_id,
                    customer_referee_id: cartData.discountData.customer_referee_id,
                    discount_per: cartData.discountData.discount_per,
                    order_referee_id: orderId,
                };
                await axios.post(`${url}createCustomerReferralCode`, referalData);

                console.log("ended");
            }
            if (cartData.discountData.refereer === true) {
                console.log("ended");
                const refreeData = {
                    customer_referrer_id: customerProfile.id,
                    order_referrer_id: orderId,
                };
                await axios.post(`${url}updateCustomerReferralCode`, refreeData);
            }
            if (cartData.discountData.discountCode === true) {
                dispatch(
                    userUsedDiscountAction({
                        userId: cartData.discountData.userId,
                        discountId: cartData.discountData.discountId,
                        discount_code: cartData.discountData.discount_code,
                    })
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <main className="checkout-page">
                <section className="breadcrumbs-wrapper">
                    <div className="container">
                        <nav aria-label="Page breadcrumb">
                            <h2 className="title2">{t(CheckOut_)}</h2>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">
                                    Home
                                </li>
                                <li className="breadcrumb-item">Your Supplements</li>
                            </ol>
                        </nav>
                    </div>
                </section>
                <section className="checkout-section section-padding">
                    <div className="container">
                        <div className="row gy-4">
                            <div className="col-xl-8">
                                <div className="box-style-2">
                                    <div className="col-lg-10">
                                        <h2 className="title text-uppercase mb-3 pb-2">
                                            {t(RecipientDetails_)}
                                        </h2>
                                        <div className="row g-3">
                                            {errorMessage && (
                                                <p style={{ color: "red", fontSize: "17px" }}>
                                                    {errorMessage}
                                                </p>
                                            )}
                                            <div className="col-lg-6">
                                                <input
                                                    type="text"
                                                    name="firstName"
                                                    id="firstName"
                                                    className={`form-control ${
                                                        shippingInfo.firstName?.length < 3
                                                            ? "is-invalid"
                                                            : ""
                                                    }`}
                                                    placeholder={t(FirstName)}
                                                    aria-describedby="helpId"
                                                    onChange={shippingChangeHandler}
                                                    value={shippingInfo.firstName}
                                                    required
                                                    minLength={3}
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <input
                                                    type="text"
                                                    name="lastName"
                                                    id="lastName"
                                                    className={`form-control ${
                                                        shippingInfo.lastName?.length < 3
                                                            ? "is-invalid"
                                                            : ""
                                                    }`}
                                                    placeholder={t(LastName)}
                                                    aria-describedby="helpId"
                                                    onChange={shippingChangeHandler}
                                                    value={shippingInfo.lastName}
                                                    required
                                                    minLength={3}
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <input
                                                    type="text"
                                                    name="companyName"
                                                    id="companyName"
                                                    className={`form-control`}
                                                    placeholder={t(CompanyName)}
                                                    aria-describedby="helpId"
                                                    onChange={shippingChangeHandler}
                                                    value={shippingInfo.companyName}
                                                />
                                            </div>
                                        </div>
                                        <h2 className="title text-uppercase mt-5 pt-4 mb-3 pb-2">
                                            {t(ShippingDetails)}
                                        </h2>
                                        <div className="row g-3">
                                            <div className="col-lg-12">
                                                <input
                                                    type="text"
                                                    name="country"
                                                    id="country"
                                                    className={`form-control ${
                                                        shippingInfo?.country?.length < 3
                                                            ? "is-invalid"
                                                            : ""
                                                    }`}
                                                    placeholder={t(CountryRegion)}
                                                    aria-describedby="helpId"
                                                    onChange={shippingChangeHandler}
                                                    value={shippingInfo.country}
                                                    required
                                                    minLength={3}
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <input
                                                    type="text"
                                                    name="address"
                                                    id="address"
                                                    className={`form-control ${
                                                        shippingInfo?.address?.length < 3
                                                            ? "is-invalid"
                                                            : ""
                                                    }`}
                                                    placeholder={t(StreetAddress)}
                                                    aria-describedby="helpId"
                                                    onChange={shippingChangeHandler}
                                                    value={shippingInfo.address}
                                                    minLength={6}
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="appartment"
                                                    id="appartment"
                                                    className={`form-control ${
                                                        shippingInfo?.appartment?.length < 3
                                                            ? ""
                                                            : ""
                                                    }`}
                                                    placeholder={t(Apartment)}
                                                    aria-describedby="helpId"
                                                    onChange={shippingChangeHandler}
                                                    value={shippingInfo.appartment}
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="state"
                                                    id="state"
                                                    className={`form-control ${
                                                        shippingInfo?.state?.length < 3
                                                            ? "is-invalid"
                                                            : ""
                                                    }`}
                                                    placeholder={t(State)}
                                                    aria-describedby="helpId"
                                                    onChange={shippingChangeHandler}
                                                    value={shippingInfo.state}
                                                    minLength={3}
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-4">
                                                <input
                                                    type="text"
                                                    name="zipCode"
                                                    id="zipCode"
                                                    className={`form-control ${
                                                        shippingInfo?.zipCode?.length < 3
                                                            ? "is-invalid"
                                                            : ""
                                                    }`}
                                                    placeholder={t(Zipcode)}
                                                    aria-describedby="helpId"
                                                    onChange={shippingChangeHandler}
                                                    value={shippingInfo.zipCode}
                                                    required
                                                    minLength={4}
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <input
                                                    type="text"
                                                    name="phoneNo"
                                                    id="phoneNo"
                                                    className={`form-control ${
                                                        shippingInfo?.phoneNo?.length < 3
                                                            ? "is-invalid"
                                                            : ""
                                                    }`}
                                                    placeholder="Phone"
                                                    aria-describedby="helpId"
                                                    onChange={shippingChangeHandler}
                                                    value={shippingInfo.phoneNo}
                                                    minLength={10}
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-6">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    aria-describedby="helpId"
                                                    onChange={shippingChangeHandler}
                                                    value={shippingInfo.email}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4">
                                <div className="box-style-2 checkout-box sticky-top">
                                    <div className="checkout-header">
                                        <h1 className="display-4 m-0">Your Order</h1>
                                        <h5>For {subscription?.title}</h5>
                                    </div>
                                    <div className="checkout-body">
                                        <div className="row">
                                            <div className="col-6">
                                                <h4>Product</h4>
                                                <h5>{cartData?.cart?.name}</h5>

                                            </div>
                                            <div className="col-6 text-end">
                                                <h4>Sub Total</h4>

                                                <h4>{cartData?.cart?.total}$</h4>
                                            </div>
                                        </div>
                                        <div className="alert alert-light" role="alert">
                                            <div className="row mx-0">
                                                <div className="form-check col">
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="flexRadioDefault1"
                                                    >
                                                        <b> 3 Months of Rise </b>
                                                    </label>
                                                </div>
                                            </div>
                                            <p>
                                                You will only be paying {cartData?.cart?.total}$.

                                            </p>
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <h4>Total</h4>
                                            </div>
                                            <div className="col-6 text-end">
                                                <h4>{cartData?.cart?.total} $</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkout-footer">
                                        {toggle && (
                                            <div>
                                                <h4>Pay With</h4>
                                                {paypalData[0].active === 1 && (
                                                    <img
                                                        src={payPalImage}
                                                        alt=""
                                                        style={{cursor: "pointer"}}
                                                        className="img-fluid d-block mx-auto"
                                                        onClick={() => {
                                                            setPayPalSelected(true);
                                                            setStripe(false);
                                                        }}
                                                    />
                                                )}
                                                <div
                                                    style={{
                                                        marginTop: "30px",
                                                    }}
                                                >
                                                    {paypalSelected &&
                                                        (subscription.duration === "1 time" ? (
                                                            <PayPalPayment
                                                                onPayment={checkOutHandler}
                                                                price={cartData?.cart.total}
                                                            />
                                                        ) : (
                                                            <PayPalSubscriptionButton
                                                                onPayment={checkOutHandler}
                                                                price={cartData?.cart.total}
                                                            />
                                                        ))}
                                                </div>

                                                {stripeData[0].active === 1 && (
                                                    <img
                                                        src={stripeImage}
                                                        alt=""
                                                        style={{cursor: "pointer"}}
                                                        className="img-fluid my-4 d-block mx-auto"
                                                        onClick={() => {
                                                            setStripe(true);
                                                            setPayPalSelected(false);
                                                        }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        {stripeSelected && (
                                            <div
                                                style={{
                                                    marginTop: "20px",
                                                    marginBottom: "20px",
                                                    backgroundColor: "white",
                                                    padding: "10px",
                                                    paddingBottom: "50px",
                                                    borderRadius: "6px",
                                                    border: "1px solid grey",
                                                }}
                                            >
                                                <h6 style={{ margin: "10px" }}>Stripe Payment</h6>
                                                <Elements stripe={stripePromise}>
                                                    <PaymentForm
                                                        onDataEntered={checkOutHandler}
                                                        price={cartData?.cart.total}
                                                    />
                                                </Elements>
                                            </div>
                                        )}
                                        {!toggle && (
                                            <button
                                                type="button"
                                                className="btn btn-custom-1 w-100"
                                                onClick={pressHandler}
                                                // disabled={paymentSuccess !== true ? true : false}
                                            >
                                                Check Out
                                            </button>
                                        )}

                                        {paymentError !== "" && (
                                            <p style={{ color: "red" }}>{paymentError}</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </div>
    );
};

export default Checkout;
