import { apiSlice } from "../app/api/apiSlice";

export const AnalyticsApiSlice = apiSlice.injectEndpoints({

    endpoints: builder => ({
        getAnalytics: builder.query({
            query: payload => ({
                url: 'settings',
                method: 'POST',
                body: { ...payload }
            }),
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
    })
})

export const {
    useGetAnalyticsQuery,
} = AnalyticsApiSlice