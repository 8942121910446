import React from "react";
import Stars from "../assets/stars-2.png";
import { useTranslation } from "react-i18next";
import { Rating, Reviews } from "../Constants";

const ReviewsOverlay = ({
  totalReviews,
  averageReviews,
  onClick,
  className,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`t-flex t-items-center t-gap-4 t-cursor-pointer ${className}`}
      onClick={onClick && onClick}
    >
      <img src={Stars} alt="" height={100} width={100} />
      <p className="t-m-0 !t-font-bold">
        ({averageReviews} {t(Rating)} {totalReviews} {t(Reviews)})
      </p>
    </div>
  );
};

export default ReviewsOverlay;
