import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetContentsQuery } from "../../features/contentsApiSlice";
import { Buffer } from "buffer";
import { Container } from "reactstrap";
import { logo } from "../../Data/Images/Home";
import { useCreateNewPasswordMutation } from "../../features/auth/authApiSlice";

const CreatePassword = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const userId = searchParams.get("userId");
  const email = searchParams.get("email");

  const idImage = Buffer.from("58").toString("base64");
  const [passwordError, setPasswordError] = useState("");
  const [password, setUpdatePassword] = useState({
    new_password: "",
    confirm_new_password: "",
  });
  const [backgroundImage, setBackgroundImage] = useState("");

  const [createNewPassword, { isLoading }] = useCreateNewPasswordMutation();

  const passChangeHandler = (e) => {
    setUpdatePassword({ ...password, [e.target.name]: e.target.value });
  };

  const isValidPassword = () => {
    const { new_password, confirm_new_password } = password;
    if (!new_password || !confirm_new_password) {
      setPasswordError("Please provide values for all fields.");
      return false;
    }
    if (
      new_password.length < 8 ||
      !/[A-Z]/.test(new_password) ||
      !/[a-z]/.test(new_password)
    ) {
      setPasswordError(
        "Password should contain at least one uppercase letter, one lowercase letter, and be at least 8 characters long."
      );
      return false;
    }

    if (new_password !== confirm_new_password) {
      setPasswordError("New password and confirm password do not match.");
      return false;
    }

    setPasswordError("");
    return true;
  };

  const passSubmitHandler = async (e) => {
    e.preventDefault();
    if (!isValidPassword()) {
      return;
    }
    try {
      const data = await createNewPassword({
        token,
        userId,
        password: password.new_password,
      }).unwrap();
      console.log("createNewPassword", data);
      toast.success("Password created successfully!");
      setTimeout(() => {
        navigate("/login");
      }, [5000]);
    } catch (err) {
      console.log("Error -> ", err);
      toast.error(err?.data?.message || "Something went wrong!");
    }
  };

  const { data: fetchHeaderImage, isSuccess: isSuccessImage } =
    useGetContentsQuery({ id: idImage, lang: "en", skip: false });

  useEffect(() => {
    if (isSuccessImage) {
      if (fetchHeaderImage.data.picture)
        setBackgroundImage(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchHeaderImage.data.picture}`
        );
    }
  }, [fetchHeaderImage, isSuccessImage]);

  return (
    <>
      <Container
        fluid={true}
        className="login-section section-padding t-pt-4 md:t-pt-8"
        style={{
          backgroundImage: `linear-gradient(var(--secondary-80),var(--secondary-80)) , url(${backgroundImage})`,
        }}
      >
        <div className="container d-flex t-flex-col align-items-center justify-content-center vh-100 t-gap-20 md:-t-mt-10">
          <div className="mlogo t-mb-2">
            <a href="/">
              <img src={logo} alt="" width={120} />
            </a>
          </div>
          <div className="t-w-full t-max-w-[400px] t-mx-auto t-bg-gray-50 t-flex t-flex-col t-gap-16 t-shadow-lg t-rounded-md !t-py-12 t-px-4">
            <div className="t-text-2xl t-font-bold t-text-center t-text-tertiary">
              Create your password
            </div>
            <form
              action=""
              className="t-flex t-flex-col t-gap-8"
              onSubmit={passSubmitHandler}
            >
              <div className="row textboxes">
                <div className="col-12 position-relative">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Email"
                    aria-describedby="helpId"
                    value={email ?? "abc@mail.com"}
                    disabled
                  />
                  <i className="fa fas fa-envelope !t-text-lg !t-mt-3" />
                </div>
              </div>
              <div className="row textboxes">
                <div className="col-12 position-relative">
                  <input
                    type="password"
                    name="new_password"
                    id="new_password"
                    className="form-control"
                    placeholder="New Password"
                    aria-describedby="helpId"
                    value={password.new_password}
                    onChange={passChangeHandler}
                  />
                  <i className="fa fas fa-key !t-text-lg !t-mt-3" />
                </div>
              </div>
              <div className="row textboxes">
                <div className="col-12 position-relative">
                  <input
                    type="password"
                    name="confirm_new_password"
                    id="confirm_new_password"
                    className="form-control"
                    placeholder="Confirm Password"
                    aria-describedby="helpId"
                    value={password.confirm_new_password}
                    onChange={passChangeHandler}
                  />
                  <i className="fa fas fa-key !t-text-lg !t-mt-3" />
                </div>
              </div>
              {passwordError && (
                <div className="text-danger !t-my-0">{passwordError}</div>
              )}
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    type="submit"
                    className="col-12 btn btn-custom-secondary fw-bold lts1 px-5"
                    disabled={isLoading}
                  >
                    Create Password
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
      <ToastContainer />
    </>
  );

  //   return (
  //     <div className=" d-flex justify-content-center mt-4">
  //       <div className="container w-50">
  //         <div className="welcome mb-5">
  //           <h4>Change Password </h4>
  //         </div>
  //         <form onSubmit={passSubmitHandler}>
  //           <div className="row gy-4">
  //             {passwordError && (
  //               <div className="text-danger mt-2">{passwordError}</div>
  //             )}
  //             <div className="col-lg-12 position-relative">
  //               <label>New Password</label>
  //               <input
  //                 type="password"
  //                 name="new_password"
  //                 id="new_password"
  //                 className="form-control"
  //                 placeholder="New Password"
  //                 aria-describedby="helpId"
  //                 value={password.new_password}
  //                 onChange={passChangeHandler}
  //               />
  //             </div>
  //             <div className="col-lg-12 position-relative">
  //               <label>Confirm Password</label>
  //               <input
  //                 type="password"
  //                 name="confirm_new_password"
  //                 id="confirm_new_password"
  //                 className="form-control"
  //                 placeholder="Confirm Password"
  //                 aria-describedby="helpId"
  //                 value={password.confirm_new_password}
  //                 onChange={passChangeHandler}
  //               />
  //             </div>
  //           </div>
  //           <div className="mt-5">
  //             <button
  //               type="submit"
  //               className="btn btn-custom-secondary fw-bold lts1 px-5"
  //             >
  //               Create Password
  //             </button>
  //           </div>
  //         </form>
  //       </div>
  //     </div>
  //   );
};

export default CreatePassword;
