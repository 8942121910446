import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Buffer } from 'buffer';
import { logo } from "../Data/Images/Home";
import { useResetPasswordLinkMutation } from "../features/auth/authApiSlice";
import { useGetContentsQuery } from "../features/contentsApiSlice";
import "../assets/scss/pages/_login.css";

const ForgetPassword = () => {
  const history = useNavigate();
  const [forgotPasswordLink, { isLoading }] = useResetPasswordLinkMutation();
  const idImage = Buffer.from("58").toString("base64");

  const [email, setEmail] = useState("");
  const [headerImage, setHeaderImage] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const forgotPassword = async (e) => {
    e.preventDefault();
    // toast.success('Password reset link sent successfully. Check Your email', {toastId: 'forgot-password'});
    setErrMsg("");
    if (!email || email === "") {
      setErrMsg("Email is required");
    } else {
      try {
        const data = await forgotPasswordLink({
          email,
        }).unwrap()
        console.log("Reset Link before ");
        toast.success(
          "Password reset link sent successfully. Check Your email",
          { toastId: "forgot-password" }
        );
        console.log("Reset Link", data);
        setTimeout(()=>{
          history("/login");
        }, [5000])
      } catch (err) {
        toast.error(err?.data?.message || "Something went wrong");
        console.log("Forget Password error => ", err);
      }
    }
  };

  const { data: fetchHeaderImage, isSuccess: isSuccessImage } =
    useGetContentsQuery({ id: idImage, lang: "en", skip: false });

  useEffect(() => {
    if (isSuccessImage) {
      if (fetchHeaderImage.data.picture)
        setHeaderImage(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchHeaderImage.data.picture}`
        );
    }
  }, [fetchHeaderImage, isSuccessImage]);

  return (
    <>
      <Container
        fluid={true}
        className="login-section section-padding pt-0"
        style={{
          backgroundImage: `linear-gradient(var(--secondary-80),var(--secondary-80)) , url(${headerImage})`,
        }}
      >
        <div className="container d-flex t-flex-col align-items-center justify-content-center vh-100 t-gap-20 md:-t-mt-10">
          <div className="mlogo t-mb-4">
            <a href="/">
              <img src={logo} alt="" width={120} />
            </a>
          </div>
          <div className="t-w-full t-max-w-[400px] t-mx-auto t-bg-gray-50 t-flex t-flex-col t-gap-16 t-shadow-lg t-rounded-md !t-py-12 t-px-4">
            <div className="t-text-2xl t-font-bold t-text-center t-text-tertiary">
              Forgot Password
            </div>
            <form action="" className="t-flex t-flex-col t-gap-8">
              <p className="t-text-lg t-font-bold !t-m-0 t-text-center">
                Enter your email to get password reset link
              </p>
              <div className="row textboxes">
                <div className="col-12 position-relative">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Email"
                    aria-describedby="helpId"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <i className="fa fas fa-envelope !t-text-lg !t-mt-3" />
                </div>
                {errMsg && (
                  <p className="t-text-left t-text-red-500 !t-mt-1">{errMsg}</p>
                )}
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  <button
                    type="button"
                    className="col-12 btn btn-custom-secondary fw-bold lts1 px-5"
                    onClick={(e) => forgotPassword(e)}
                    disabled={isLoading}
                  >
                    Send Password Reset Link
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Container>
      <ToastContainer />
    </>
  );
};

export default ForgetPassword;
