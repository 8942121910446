import React, { useEffect, useState } from "react";
import DashboardMenuItem from "./DashboardMenuItem";
import { Icon } from "../../elements/icon";
import { useDispatch, useSelector } from "react-redux";
import { logOutUserAction } from "../../../app/api/useDataManage/userActions";
import { useTranslation } from "react-i18next";
import { selectCurrentLanguage } from "../../../features/langSlice";
import { useNavigate } from "react-router-dom";

import {
  DashboardTitle,
  ReferralCode,
  Profile,
  Address,
  MyOrders,
  ChangePassword,
  AskSpecialist,
  InquiryTitle,
  LogoutTitle,
  CheckIn,
  Referrals,
  ManageSubscription,
  CustomerService,
  Benefits,
  RetakeTest,
  Ingredients,
} from "../../../Constants";
import axios from "axios";

const DashboardMenu = ({ setIsMenuOpen }) => {
  const selectedLang = useSelector(selectCurrentLanguage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { message, isAuthenticated } = useSelector((state) => state.user);

  const [lang, setLang] = useState();
  const token = localStorage.getItem("customer_token");
  const quiz_id = localStorage.getItem("quiz_id");
  const [isDispatch, setIsDispatch] = useState(false);

  const MENU_ITEMS = [
    { name: t(DashboardTitle), icon: "dashboard", href: "/dashboard" },
    { name: t(Profile), icon: "user", href: "/dashboard/profile" },
    { name: t(CheckIn), icon: "calendar", href: "/customer/checkin" },
    {
      name: t(ManageSubscription),
      icon: "subscription",
      href: "/dashboard/subscription",
    },
    { name: t(MyOrders), icon: "cart", href: "/dashboard/orders" },
    { name: "Retake Test", icon: "refresh", href: "/customer/retaketest" },
    {
      name: t(CustomerService),
      icon: "customerService",
      href: "/customer/service",
    },
    {
      name: t(AskSpecialist),
      icon: "specialist",
      href: "/customer/askspecialist",
    },
    { name: t(Referrals), icon: "forward", href: "/dashboard/referralcode" },
  ];

  const MAIN_AREA_ITEMS = [
    { name: t(Ingredients), icon: "dashboard", href: "/ingredients" },
    ...(quiz_id && !token
      ? [{ name: "Cart", icon: "cart", href: "/product-suggestion" }]
      : []),
  ];

  useEffect(() => {
    setLang(selectedLang === "us" ? "en" : selectedLang);
  }, [lang, selectedLang]);

  useEffect(() => {
    if (message === "Logged out successfully." && isDispatch) {
      localStorage.removeItem("token");
      window.location.href = "/";
    }
  }, [message, isDispatch, navigate]);

  const logOutUser = () => {
    dispatch(logOutUserAction(token));
    setIsDispatch(true);
  };

  const backfillIncompleteQuiz = async () => {
    try {
      const data = await axios.get(
        `http://localhost:3000/api/stripe-data-backfill`
      );
      console.log("data => ", data);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div className="t-flex t-flex-col t-gap-1">
      {token &&
      token !== "" &&
      (window.location.href.includes("dashboard") ||
        window.location.href.includes("customer")) ? (
        <>
          {MENU_ITEMS?.map((item) => (
            <DashboardMenuItem
              {...item}
              key={"link-" + item.href}
              setIsMenuOpen={setIsMenuOpen && setIsMenuOpen}
            />
          ))}
          <div
            className={`t-group t-flex !t-items-center t-gap-4 t-px-4 t-py-2 hover:t-bg-gray-100 t-cursor-pointer`}
            onClick={logOutUser}
          >
            <Icon name="logout" color="#475467" size="24" className="" />
            <p
              className={`t-text-sm md:t-text-base !t-font-bold t-m-0 t-text-gray-600`}
            >
              Logout
            </p>
          </div>
        </>
      ) : (
        <>
          {/* <button onClick={backfillIncompleteQuiz}>Stripe Data Backfill</button> */}
          {MAIN_AREA_ITEMS?.map((item) => (
            <DashboardMenuItem
              {...item}
              key={"link-" + item.href}
              setIsMenuOpen={setIsMenuOpen && setIsMenuOpen}
            />
          ))}
          {isAuthenticated && token !== "" ? (
            <DashboardMenuItem
              name="Profile"
              icon="user"
              href="/dashboard"
              setIsMenuOpen={setIsMenuOpen && setIsMenuOpen}
            />
          ) : (
            <DashboardMenuItem
              name="Login"
              icon="logout"
              href="/login"
              setIsMenuOpen={setIsMenuOpen && setIsMenuOpen}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DashboardMenu;
