import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null, 
        token: null
    },
    reducers: {
        setCredentials: (state, action) => {
            
            //const { user, access_token, expires_in, storage } = action.payload
            const { access_token, expires_in, storage } = action.payload
            //console.log(access_token)
            if(storage) {

                //localStorage.setItem("userInfo", JSON.stringify(user));
                localStorage.setItem("customer_token", access_token);
                
            }

            //state.user = user
            state.token = access_token
        },
        logOut: (state, action) => {
            //state.user = null
            state.token = null

            //localStorage.removeItem("userInfo");
            // localStorage.setItem("login", false);
            // localStorage.removeItem("expires_in");
            // localStorage.removeItem("profileURL");
            // localStorage.removeItem("token");
            // localStorage.removeItem("auth0_profile");
            // localStorage.removeItem("Name");

            // localStorage.setItem("authenticated", false);
        }
    },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

//export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token