import { apiSlice } from "../app/api/apiSlice";

export const VisotorApiSlice = apiSlice.injectEndpoints({

    endpoints: builder => ({
        subscribe: builder.mutation({
            query: payload => ({
                url: 'visitor/subscribe',
                method: 'POST',
                body: { ...payload }
            })
        }),
    })
})

export const {
    useSubscribeMutation,
} = VisotorApiSlice