import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  useAddOmnisendEventMutation,
  useGetAssetsQuery,
  useGetIngredientPricesQuery,
  useGetIngredientsCodesQuery,
  useGetIngredientsQuery,
  useGetProductPricesQuery,
  useUpdateQuizIngredientsMutation,
} from "../features/quizApiSlice";
import { calculateIngredientPrice } from "../utils/productSuggestions";
import { toast } from "react-toastify";

const MICRO_INGREDIENT_IDS = [25, 18, 20, 26, 28, 30];
const ONE_OFF_SUB = {
  picture: "resources/images/boxsubs-monthly.jpg",
  duration: "30 days",
  description: "One time subscription for 30 days",
  title: "30 days",
  id: 999,
};

const useProduct = () => {
  let { state } = useLocation();

  const [lang, setLang] = useState();
  const [data, setData] = useState([]);
  const [focusArea, setFocusArea] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [newSubs, setNewSubs] = useState([]);

  const [quizId, setQuizId] = useState(0);
  const [user, setUser] = useState();
  const [quantitiesChanged, setQuantitiesChanged] = useState(false);
  const [error, setError] = useState({});
  const [prices, setPrices] = useState({});
  const [pricesData, setPricesData] = useState({});
  const [totals, setTotals] = useState({
    oneMonth: 0,
    threeMonths: 0,
    sixMonths: 0,
  });
  const [continent, setContinent] = useState("eu");
  const [paymentLink, setPaymentLink] = useState(null);
  const [subscribtion, setSubscription] = useState({
    title: "",
    duration: "",
    description: "",
    subId: 1,
    expirey: Date,
  });
  const [addIngredientsModal, setAddIngredientsModal] = useState(false);
  const [token] = useState(localStorage.getItem("customer_token"));

  // All queries and mutations
  const [updateQuizIngredientsQuantity] = useUpdateQuizIngredientsMutation();
  const [addOmnisendEventMutation] = useAddOmnisendEventMutation();

  const { data: allProductPrices, isLoading: isProductPricingLoading } =
    useGetProductPricesQuery();
  const { data: focusAreaImages } = useGetAssetsQuery("focusArea");
  const { data: focusAreaIcons } = useGetAssetsQuery("focusAreaIcon");
  const { data: allIngredients } = useGetIngredientsQuery();
  const { data: allIngredientCodes } = useGetIngredientsCodesQuery();
  const { data: allIngredientPrices, isLoading: allIngredientPriceLoading } =
    useGetIngredientPricesQuery(lang);
  const { data: news } = useGetAssetsQuery("homepageNews");

  const onSelectPlan = (selectedPlan) => {
    if (selectedPlan.duration === "6 months") {
      const price = allProductPrices?.data?.find(
        (price) =>
          price.duration === "6 months" &&
          price.amount === Math.round(parseFloat(totals["sixMonths"]))
      );
      setPaymentLink(price?.payment_link);
    } else if (selectedPlan.duration === "3 months") {
      const price = allProductPrices?.data?.find(
        (price) =>
          price.duration === "3 months" &&
          price.amount === Math.round(parseFloat(totals["threeMonths"]))
      );
      setPaymentLink(price?.payment_link);
    } else if (selectedPlan.duration === "1 month") {
      const price = allProductPrices?.data?.find(
        (price) =>
          price.duration === "1 month" &&
          price.amount === Math.round(parseFloat(totals["oneMonth"]))
      );
      setPaymentLink(price?.payment_link);
    } else if (selectedPlan.duration === "30 days") {
      const price = allProductPrices?.data?.find(
        (price) =>
          price.duration === "30 days" &&
          price.amount === Math.round(parseFloat(totals["oneMonth"]))
      );
      setPaymentLink(price?.payment_link);
    }

    subscribtion.title = selectedPlan.title;
    subscribtion.duration = selectedPlan.duration;
    subscribtion.subId = selectedPlan.id;
    subscribtion.description = selectedPlan.description;
    subscribtion.expirey = new Date(Date.now()).toLocaleDateString();
    const digit = parseInt(selectedPlan.duration.match(/\d+/)[0]);

    if (digit !== 0 && selectedPlan.duration !== "1 time") {
      let newDate = new Date(
        Date.now() + Number(digit) * 30 * 24 * 60 * 60 * 1000
      ).toLocaleDateString();
      subscribtion.expirey = newDate;
    }
  };

  const onAddQuantity = (item, i, isCustomFormula) => {
    setQuantitiesChanged(true);
    const ingredient = allIngredients?.data?.find(
      (i) =>
        i.ingredient === item.ingredient || i.ingredient_de === item?.ingredient
    );
    const ingredientData = allIngredientCodes?.data?.find(
      (i) => i?.code === ingredient?.id
    );

    const maxQuantity = isCustomFormula
      ? parseFloat(ingredientData?.limit_professionals)
      : parseFloat(ingredientData?.bfr_2021);
    const euNrv = parseFloat(ingredientData?.eu_nrv);

    setData((prev) => {
      const newData = [...prev];
      const newQuantity = parseFloat(newData[i].daily_dose) + maxQuantity * 0.1;
      if (
        newQuantity > 0 &&
        parseFloat(prev[i].daily_dose) !== parseFloat(maxQuantity)
      ) {
        calculateIngredientPrice(
          item,
          newQuantity,
          setPrices,
          setPaymentLink,
          setSubscription,
          allIngredientPrices
        );
      }

      // SHOULD BE LESS THEN THE THRESHOD QUANTITY OF THE INGREDIENT
      newData[i] = {
        ...newData[i],
        daily_dose: newQuantity >= maxQuantity ? maxQuantity : newQuantity,
      };

      if (
        parseFloat(ingredientData?.eu_nrv) > 0 &&
        parseFloat(prev[i].daily_dose) !== parseFloat(maxQuantity)
      ) {
        newData[i] = {
          ...newData[i],
          per_eu_nrv: parseInt((newQuantity / euNrv) * 100),
        };
      }
      return newData;
    });

    const updatedQuantity = parseFloat(item.daily_dose) + maxQuantity * 0.1;
    if (updatedQuantity > maxQuantity && updatedQuantity <= maxQuantity) {
      setError({ ...error, [item.ingredient]: true });
    } else {
      setError({ ...error, [item.ingredient]: false });
    }
  };

  const onRemoveQuantity = (item, i, isCustomFormula) => {
    setQuantitiesChanged(true);
    const ingredient = allIngredients?.data?.find(
      (i) =>
        i.ingredient === item.ingredient || i.ingredient_de === item?.ingredient
    );
    const ingredientData = allIngredientCodes?.data?.find(
      (i) => i.code === ingredient?.id
    );

    const maxQuantity = isCustomFormula
      ? parseFloat(ingredientData?.limit_professionals)
      : parseFloat(ingredientData?.bfr_2021);

    const euNrv = parseFloat(ingredientData?.eu_nrv);

    setData((prev) => {
      const newData = [...prev];
      const newQuantity =
        parseFloat(newData[i].daily_dose).toFixed(5) - maxQuantity * 0.1;
      calculateIngredientPrice(
        item,
        newQuantity,
        setPrices,
        setPaymentLink,
        setSubscription,
        allIngredientPrices
      );

      // SHOULD BE GREATER THEN 0
      newData[i] = {
        ...newData[i],
        daily_dose: newQuantity < 0 ? 0 : newQuantity,
      };
      if (parseFloat(ingredientData?.eu_nrv) > 0) {
        newData[i] = {
          ...newData[i],
          per_eu_nrv:
            newQuantity < 0 ? 0 : parseInt((newQuantity / euNrv) * 100),
        };
      }
      return newData;
    });
    const updatedQuantity = parseFloat(item?.daily_dose) - maxQuantity * 0.1;
    if (updatedQuantity > maxQuantity) {
      setError({ ...error, [item.ingredient]: true });
    } else {
      setError({ ...error, [item.ingredient]: false });
    }
  };

  const onSubmitData = (payLink) => {
    const filteredObjects = data.filter((obj1) => {
      const mainIngredients = ingredients?.find((obj2) => obj2.id === obj1.id);
      return mainIngredients && obj1.daily_dose !== mainIngredients.daily_dose;
    });
    const addedItems = [];
    const removedItems = [];

    // Find newly added items
    data?.forEach((item) => {
      const ingFound = ingredients?.find(
        (ing) => ing?.ingredient_id === item?.ingredient_id
      );
      if (!ingFound) {
        addedItems.push(item);
      }
    });

    // Find removed items
    ingredients?.forEach((oldItem) => {
      if (
        !data?.some(
          (newItem) => newItem.ingredient_id === oldItem.ingredient_id
        )
      ) {
        removedItems.push(oldItem);
      }
    });

    const email = state?.user?.user?.email
      ? state?.user?.user?.email
      : user?.email
      ? user?.email
      : null;
    const userId = state?.user?.user?.id
      ? state?.user?.user?.id
      : user?.id
      ? user?.id
      : null;

    if (
      filteredObjects.length > 0 ||
      addedItems.length > 0 ||
      removedItems.length > 0
    ) {
      try {
        updateQuizIngredientsQuantity({
          updatedItems: filteredObjects,
          addedItems,
          removedItems,
          quizId,
        }).then((res) => {
          addOmnisendEventMutation({
            email,
            url: `${payLink}?prefilled_email=${email}&client_reference_id=${userId}`,
            subscribtion,
            price:
              subscribtion.duration === "6 months"
                ? Math.round(parseFloat(totals["sixMonths"]))
                : subscribtion.duration === "3 months"
                ? Math.round(parseFloat(totals["threeMonths"]))
                : Math.round(parseFloat(totals["oneMonth"])),
          })
            .then((res) => {})
            .catch((err) => console.log("Error event ", err));
          window.location.href = `${payLink}?prefilled_email=${email}&client_reference_id=${userId}`;
        });
      } catch (err) {
        toast.error(err?.message || "Something went wrong");
        console.log("err => ", err);
      }
    } else {
      window.location.href = `${payLink}?prefilled_email=${email}&client_reference_id=${userId}`;
      addOmnisendEventMutation({
        email,
        url: `${payLink}?prefilled_email=${email}&client_reference_id=${userId}`,
        subscribtion,
        price:
          subscribtion.duration === "6 months"
            ? Math.round(parseFloat(totals["sixMonths"]))
            : subscribtion.duration === "3 months"
            ? Math.round(parseFloat(totals["threeMonths"]))
            : Math.round(parseFloat(totals["oneMonth"])),
      })
        .then((res) => {})
        .catch((err) => console.log("Error event ", err));
    }
  };

  const removeIngredient = (ing) => {
    const tempIngredients = [...data];
    const filteredIngredients = tempIngredients?.filter(
      (ingredient) => ingredient?.ingredient_id !== ing?.id
    );
    setData(filteredIngredients);
    const tempPrices = { ...prices };
    delete tempPrices[ing.ingredient];
    setPrices(tempPrices);
    setQuantitiesChanged(true);
    setPaymentLink(null);
  };

  const addIngredient = (ing) => {
    const prevRemovedIngredient = ingredients?.find(
      (item) => item?.ingredient_id === ing?.id
    );
    const ingData = {
      daily_dose: 0,
      description: ing?.description,
      ingredient: ing?.ingredient,
      ingredient_id: ing?.id,
      per_eu_nrv: 0,
    };
    setData((prev) => [
      ...prev,
      prevRemovedIngredient ? prevRemovedIngredient : ingData,
    ]);
    setQuantitiesChanged(true);
    setPaymentLink(null);
    const prevPrice = Object.keys(pricesData).find(
      (key) => key === ing?.ingredient
    );
    if (prevPrice) {
      setPrices((prev) => ({ ...prev, [prevPrice]: pricesData[prevPrice] }));
    } else {
      setPrices((prev) => ({ ...prev, [ing.ingredient]: 0 }));
    }
  };

  const removeIngredientFromBin = (ing) => {
    const tempIngredients = [...data];
    const filteredIngredients = tempIngredients?.filter(
      (ingredient) => ingredient.ingredient_id !== ing.ingredient_id
    );
    setData(filteredIngredients);

    const tempPrices = { ...prices };
    delete tempPrices[ing.ingredient];
    setPrices(tempPrices);
    setQuantitiesChanged(true);
    setPaymentLink(null);
  };

  const onInputChange = (
    e,
    i,
    item,
    euNrv,
    ingredientData,
    isCustomFormula
  ) => {
    let value = e.target.value;
    if (MICRO_INGREDIENT_IDS.includes(item?.ingredient_id)) {
      value = value / 1000;
    }
    const maxValue = isCustomFormula
      ? parseFloat(ingredientData?.limit_professionals)
      : parseFloat(ingredientData?.bfr_2021);

    if (value > maxValue || value < 0) {
      setError({
        ...error,
        [item.ingredient]: true,
      });
    } else {
      setError({
        ...error,
        [item.ingredient]: false,
      });
    }
    setQuantitiesChanged(true);
    calculateIngredientPrice(
      item,
      value,
      setPrices,
      setPaymentLink,
      setSubscription,
      allIngredientPrices
    );
    setData((prev) => {
      const newData = [...prev];

      newData[i] = {
        ...newData[i],
        daily_dose: value,
        per_eu_nrv:
          parseFloat(ingredientData?.eu_nrv) <= 0
            ? 0
            : parseInt((value / euNrv) * 100),
      };
      return newData;
    });
  };

  const onSelectOneOff = (selectedPlan) => {
    if (selectedPlan.duration === "30 days") {
      const price = allProductPrices?.data?.find(
        (price) =>
          price.duration === "30 days" &&
          price.amount === Math.round(parseFloat(totals["oneMonth"]))
      );
      setPaymentLink(price?.payment_link);
      onSubmitData(price?.payment_link);
    }
  };

  return {
    onInputChange,
    removeIngredientFromBin,
    addIngredient,
    removeIngredient,
    onSubmitData,
    onRemoveQuantity,
    onAddQuantity,
    onSelectPlan,
    focusAreaImages,
    focusAreaIcons,
    allIngredients,
    allIngredientCodes,
    allIngredientPrices,
    allIngredientPriceLoading,
    allProductPrices,
    isProductPricingLoading,
    news,
    updateQuizIngredientsQuantity,
    onSelectOneOff,
    //states
    lang,
    setLang,
    data,
    setData,
    focusArea,
    setFocusArea,
    ingredients,
    setIngredients,
    quizId,
    setQuizId,
    user,
    setUser,
    quantitiesChanged,
    setQuantitiesChanged,
    error,
    setError,
    prices,
    setPrices,
    pricesData,
    setPricesData,
    totals,
    setTotals,
    continent,
    setContinent,
    paymentLink,
    setPaymentLink,
    subscribtion,
    setSubscription,
    token,
    addIngredientsModal,
    setAddIngredientsModal,
    newSubs,
    setNewSubs,
    ONE_OFF_SUB,
  };
};

export default useProduct;
