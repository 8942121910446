import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createCustomerProfileActions,
  getCustomerProfileAction,
  getProfileActions,
  updateUserProfieAction,
} from '../../app/api/useDataManage/userActions';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Select from 'react-select';
import DashboardWrapper from './components/DashboardWrapper';
import CustomerAddress from './CustomerAddress';
import ChangePassword from './ChangePassword';
const gender = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

function Profile() {
  const dispatch = useDispatch();
  const { errorMessage, user, customerProfile } = useSelector(
    (state) => state.user
  );
  const [token] = useState(localStorage.getItem('customer_token'));
  const [hasDisplayedError, setHasDisplayedError] = useState(true);

  console.log(customerProfile);
  useEffect(() => {
    if (token) {
      dispatch(getProfileActions(token));
      dispatch(getCustomerProfileAction(token));
    }
  }, [dispatch, token]);

  const [update, setUpdates] = useState({
    first_name: '',
    last_name: '',
    gender: '',
    phone: '',
  });

  const handleCountryChange = (selectedOption) => {
    console.log(selectedOption);
    let target = { name: 'gender', value: selectedOption.value };
    updateInputs({ target });
  };

  useEffect(() => {
    if (errorMessage !== '' && !hasDisplayedError) {
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setHasDisplayedError(true);
    }
    if (errorMessage === '' && !hasDisplayedError) {
      toast.success('Success!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      setHasDisplayedError(true);
    }
  }, [errorMessage, hasDisplayedError]);

  useEffect(() => {
    // Update the 'update' state when the user data is available
    setUpdates({
      first_name: user?.name,
      last_name: customerProfile?.last_name,
      gender: customerProfile?.gender,
      phone: customerProfile?.phone,
    });
  }, [user, customerProfile]);

  const updateInputs = (e) => {
    //e.preventDefault()
    setUpdates({ ...update, [e.target.name]: e.target.value });
    console.log(update);
  };

  const updateHandler = (e) => {
    e.preventDefault();
    console.log('token: ', token);
    const updated = {
      token: token,
      user: update,
    };
    setHasDisplayedError(false);

    if (customerProfile === null || customerProfile === undefined) {
      dispatch(createCustomerProfileActions(updated));
    } else if (customerProfile !== null) {
      dispatch(updateUserProfieAction(updated));
    }
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '23px',
      height: '40px', // Adjust the height as needed
    }),
  };

  return (
    <DashboardWrapper title={'Personal Information'}>
      <div className='col-xl-8 col-lg-8 user-inner'>
        <div className='profile-reviews-wrapper t-flex t-flex-col t-gap-12'>
          <div className='container'>
            <div className='welcome mb-4'>
              <h4 className='t-font-bold'>Personal Information </h4>
            </div>
            <form onSubmit={updateHandler}>
              <div className='row gy-4'>
                <div className='col-lg-6 t-flex t-flex-col t-items-start t-gap-0 position-relative'>
                  <label className='t-text-gray-600 !t-ml-0'>First Name</label>
                  <input
                    type='text'
                    name='first_name'
                    id='first_name'
                    placeholder='Enter first name'
                    className={`form-control t-h-[40px] !t-pl-3 ${
                      update?.first_name?.length < 3 ? 'is-invalid' : ''
                    }`}
                    defaultValue={update.first_name}
                    onChange={updateInputs}
                    required
                    minLength={3}
                  />
                </div>
                <div className='col-lg-6 position-relative'>
                  <label className='t-text-gray-600 !t-ml-0'>Last Name</label>
                  <input
                    name='last_name'
                    id='last_name'
                    placeholder='Enter last name'
                    className={`form-control t-h-[40px] !t-pl-3 ${
                      update?.last_name?.length < 3 ? 'is-invalid' : ''
                    }`}
                    defaultValue={update.last_name}
                    onChange={updateInputs}
                    required
                    minLength={3}
                  />
                </div>
                <div className='col-lg-12 position-relative'>
                  <label className='t-text-gray-600 !t-ml-0'>Email</label>
                  <input
                    type='text'
                    name='email'
                    placeholder='Enter your email'
                    id='email'
                    className='form-control t-h-[40px] !t-pl-3'
                    value={user?.email}
                  />
                </div>
                <div className='col-lg-6 position-relative'>
                  <label className='t-text-gray-600 !t-ml-0'>Phone</label>
                  <input
                    type='text'
                    name='phone'
                    id='phone'
                    placeholder='Enter your phone'
                    className={`form-control t-h-[40px] !t-pl-3 ${
                      update?.phone?.length < 10 ? 'is-invalid' : ''
                    }`}
                    value={update.phone}
                    onChange={updateInputs}
                  />
                </div>
                <div className='col-lg-6 position-relative rounded-pill'>
                  <label className='t-text-gray-600 !t-ml-0'>Gender</label>
                  <Select
                    options={gender}
                    value={gender.find((c) => c.value === update.gender)}
                    onChange={handleCountryChange}
                    styles={customStyles}
                  />
                </div>
              </div>
              <div className='t-mt-6'>
                <button
                  type='submit'
                  className='btn btn-custom-secondary fw-bold lts1 px-5'
                >
                  Update
                </button>
              </div>
            </form>
          </div>
          <CustomerAddress />
          <ChangePassword />
        </div>
        <div className='blog-detail-inner'></div>
      </div>
    </DashboardWrapper>
  );
}

export default Profile;
