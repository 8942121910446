import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const URL = `${process.env.REACT_APP_BASE_URL}`;
const initialState = {
  errMsg: "",
  subs: [],
  isLoading: false,
  fetchedProduct: null,
};

const subSlice = createSlice({
  name: "subs",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getSubsActions.fulfilled, (state, { payload }) => {
      if (payload?.subscriptions?.length !== 0) {
        state.isLoading = false;
        state.subs = payload.subscriptions;
        state.errMsg = "";
      }
      if (payload.message) {
        state.errMsg = payload.message;
      }
    });
    builder.addCase(getProductForWebAction.fulfilled, (state, { payload }) => {
      if (payload.product !== null || payload.product !== undefined) {
        state.fetchedProduct = payload.product;
        state.errMsg = "";
      }
      if (payload.message) {
        state.errMsg = payload.message;
      }
    });
  },
});

export const getSubsActions = createAsyncThunk("sub/get", async (lang) => {
  try {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}subscriptions?lang=${lang}`
    );

    return data;
  } catch (error) {
    let message = error.response.data.message;
    return { message };
  }
});

export const getProductForWebAction = createAsyncThunk(
  "product/get",
  async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}customer/getProduct`
      );

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const subReducers = subSlice.reducer;
