import { createSlice } from "@reduxjs/toolkit"

const langSlice = createSlice({
    name: 'lang',
    initialState: {
        language: null
    },
    reducers: {
        setLanguage: (state, action) => {
            const { lng } = action.payload
            state.language = lng
        }
    },
})

export const { setLanguage } = langSlice.actions

export default langSlice.reducer

export const selectCurrentLanguage = (state) => state.lang.language