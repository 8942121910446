import React, { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector } from "react-redux";

const URL = `${process.env.REACT_APP_BASE_URL}`;
function PayPalSubscriptionButton({ onPayment, price }) {
    const [product, setProduct] = useState(
        JSON.parse(localStorage.getItem("product"))
    );
    const [subscription, setsubscription] = useState(
        JSON.parse(localStorage.getItem("subscription"))
    );
    const { gatwayInfo } = useSelector((state) => state.user);

    const paypalData = gatwayInfo.filter((item) => item.company === "paypal");
    const initialOptions = {
        "client-id": paypalData[0].client_id,
        currency: paypalData[0].currency === "dollar" ? "USD" : "EUR",
        vault: true,
    };
    const createOrder = async (data, actions) => {
        // Order is created on the server and the order id is returned

        const response = await fetch(`${URL}createPayPalSub`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                cart: [
                    {
                        sku: "001",
                        price: `${price}`,
                        productName: `${product.name}`,
                        subscriptionDuration: `${subscription.duration}`,
                    },
                ],
            }),
        });
        const respo = await response.json();

        return actions.subscription.create({
            plan_id: respo.id, // Creates the subscription
        });
    };
    const onApprove = async (data) => {
        // Order is captured on the server and the response is returned to the browser
        try {
            // console.log("sucesssssssssss", data);
            // alert("You have successfully subscribed to " + data.subscriptionID);
            if (data.subscriptionID) {
                onPayment({
                    transactionId: data.subscriptionID,
                    subscriptionId: data.subscriptionID,
                    customerId: data.subscriptionID,
                    paymentJson: data,
                });
            }
        } catch (error) {
            onPayment({
                error: error.message,
            });
        }
    };
    return (
        <PayPalScriptProvider options={initialOptions}>
            <PayPalButtons
                createSubscription={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
            />
        </PayPalScriptProvider>
    );
}

export default PayPalSubscriptionButton;