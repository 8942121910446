import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {selectCurrentLanguage} from "../../features/langSlice";
import {useTranslation} from "react-i18next";
import {Home, UserDashboard} from "../../Constants";

function TopNavBar({ title }) {
    const selectedLang = useSelector(selectCurrentLanguage)
    const {t} = useTranslation();
    const [lang, setLang] = useState ()


    useEffect(() => {

        setLang(selectedLang==='us'?'en':selectedLang)

    },[lang, selectedLang]);

    return (
        <nav aria-label="Page breadcrumb" className='!t-flex !t-flex-col !t-gap-2 !t-m-0'>
            <h2 className="title2 !t-text-2xl !t-m-0">{t(UserDashboard)}</h2>
            <ol className="breadcrumb !t-text-gray-500  !t-m-0">
                <li className="breadcrumb-item active !t-text-gray-500" aria-current="page">
                    {t(Home)}
                </li>
                <li className="breadcrumb-item">{title}</li>
            </ol>
        </nav>
    );
}

export default TopNavBar;