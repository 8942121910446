import React, { useState, useRef, useEffect } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { logo } from '../../Data/Images/Home';
import QuizModule from './QuizModule';
import { useSelector } from 'react-redux';
import { selectCurrentLanguage } from '../../features/langSlice';
import { ToastContainer } from 'react-toastify';
import { Buffer } from 'buffer';
import { useGetContentsQuery } from '../../features/contentsApiSlice';
import { Previous } from '../../Constants';
import { useTranslation } from 'react-i18next';

const Quiz = () => {
  const idImage = Buffer.from('58').toString('base64');
  const [headerImage, setHeaderImage] = useState('');

  const previousRef = useRef();
  const [showProgressBar, setShowProgressBar] = useState(true);

  const selectedLang = useSelector(selectCurrentLanguage);
  const [lang, setLang] = useState('en');
  const [skip, setSkip] = useState(false);
  const { t } = useTranslation();

  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    setLang(selectedLang === 'us' ? 'en' : selectedLang);

    //console.log(lang)
  }, [lang, selectedLang]);

  const {
    data: fetchHeaderImage,
    isSuccess: isSuccessImage,
    refetch: refetchImage,
  } = useGetContentsQuery({ id: idImage, lang: 'en', skip: skip });

  useEffect(() => {
    if (isSuccessImage) {
      //console.log(fetchHeaderImage.data.picture)
      if (fetchHeaderImage.data.picture)
        setHeaderImage(
          `${process.env.REACT_APP_PUBLIC_URL}${fetchHeaderImage.data.picture}`
        );
    }
  }, [fetchHeaderImage]);

  const previousHandle = () => {
    if (previousRef.current) {
      previousRef.current.handlePrevious();
    }
  };

  return (
    <div style={{ backgroundColor: '#345432', position: 'absolute' }}>
      <div
        className='quiz'
        style={{
          backgroundImage: `linear-gradient(var(--secondary-80),var(--secondary-80))`,
        }}
      >
        <div className='d-flex justify-content-between w-100 align-items-center'>
          <div className='col-3 ps-2 me-auto'>
            <button
              type='button'
              className='btn btn-custom-secondary btn-sm'
              onClick={previousHandle}
            >
              {t(Previous)}
            </button>
          </div>
          <div className='col-md-6 col-sm-4 text-center mx-md-auto'>
            <img src={logo} alt='' width={100} className='t-mx-auto' />
          </div>
          <div className='col-3 ps-2 ms-auto'>&nbsp;</div>
        </div>
        <div className='container'>
          <QuizModule
            setProgressValue={setProgressValue}
            ref={previousRef}
            setShowProgressBar={setShowProgressBar}
            progressValue={progressValue}
          />
          <div style={{ height: '100%' }}>&nbsp;</div>
        </div>

        <div className='quiz-footer'>
          {showProgressBar && (
            <ProgressBar
              className='custom-progress-bar'
              style={{ width: '100%', height: '60px' }}
              now={progressValue}
            />
          )}
          {showProgressBar && (
            <div className='progressbar-label'>{`${progressValue}%`}</div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Quiz;
