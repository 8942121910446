import React, { useEffect } from "react";

export const WIDGET_DATA = {
  store: "weriise.co",
  sku: "",
  lang: "en",
  carousel_type: "topHeader",
  styles_carousel: "CarouselWidget--topHeader--withcards",
  options: {
    general: {
      review_type: "company, product",
      min_reviews: "1",
      max_reviews: "20",
      address_format: "CITY, COUNTRY",
      enable_auto_scroll: 10000,
    },
    header: {
      enable_overall_stars: true,
      rating_decimal_places: 2,
    },
    reviews: {
      enable_customer_name: true,
      enable_customer_location: true,
      enable_verified_badge: true,
      enable_subscriber_badge: true,
      enable_recommends_badge: true,
      enable_photos: true,
      enable_videos: true,
      enable_review_date: true,
      disable_same_customer: true,
      min_review_percent: 4,
      third_party_source: true,
      hide_empty_reviews: true,
      enable_product_name: true,
      tags: "",
      branch: "",
      enable_branch_name: false,
    },
    popups: {
      enable_review_popups: true,
      enable_helpful_buttons: true,
      enable_helpful_count: true,
      enable_share_buttons: true,
    },
  },
  translations: {
    verified_customer: "Verified Customer",
  },
  styles: {
    "--base-font-size": "16px",
    "--base-maxwidth": "100%",
  },
};

const ReviewsMobile = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://widget.reviews.io/carousel-inline-iframeless/dist.js";
    script.async = true;

    script.onload = () => {
      new window.carouselInlineWidget(
        "reviewsio-carousel-widget-mobile",
        WIDGET_DATA
      );
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        @import url('https://assets.reviews.io/css/widgets/carousel-widget.css?_t=2024062614');
        @import url('https://assets.reviews.io/iconfont/reviewsio-icons/style.css?_t=2024062614');
      `,
        }}
      />
      <div id="reviewsio-carousel-widget-mobile"></div>
    </>
  );
};

export default ReviewsMobile;
