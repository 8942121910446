import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom';

const MetaPixel = () => {
  const location = useLocation();  
  const META_PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID; 

  useEffect(() => {
    const trackPageView = () => {
        if (window.fbq) {
            window.fbq('track', 'PageView');
        } else {
            console.error('FBQ not initialized');
        }
    };

    // Check if fbq is loaded, otherwise wait for a bit
    if (window.fbq) {
        trackPageView();
    } else {
        const intervalId = setInterval(() => {
            if (window.fbq) {
                clearInterval(intervalId);
                trackPageView();
            }
        }, 100); // Check every 100 milliseconds
    }
}, [location.pathname]);


  return (
    <Helmet>
      <script id="facebook-pixel-script" type="text/javascript">
        {`
          !function(f,b,e,v,n,t,s) {
            if(f.fbq)return;
            n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;
            n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];
            t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s);
          }(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${META_PIXEL_ID}');
          // Initial PageView tracked via useEffect
        `}
      </script>
      <noscript>
        {`
          <img height="1" width="1" style="display:none"
            src="https://www.facebook.com/tr?id=${META_PIXEL_ID}&ev=PageView&noscript=1" />
        `}
      </noscript>
    </Helmet>
  );
};

export default MetaPixel;
