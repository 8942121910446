import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = `${process.env.REACT_APP_BASE_URL}customer`;
const updateUserAction = createAsyncThunk('user/updateProfile', async () => {
  const { data } = await axios.put(``);
});

export const getProfileActions = createAsyncThunk(
  'user/getPrfile',
  async (token) => {
    try {
      const { data } = await axios.get(
        `${url}/getProfile`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const updateUserProfieAction = createAsyncThunk(
  'user/updateProfile',
  async (userData) => {
    try {
      const { data } = await axios.put(
        `${url}/updateCustomerProfile`,
        userData?.user,
        {
          headers: {
            Authorization: `Bearer ${userData?.token}`,
          },
        }
      );

      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const updatePasswordActions = createAsyncThunk(
  'user/updatePass',
  async (passData) => {
    try {
      const { data } = await axios.put(`${url}/updatePass`, passData?.data, {
        headers: {
          Authorization: `Bearer ${passData?.token}`,
        },
      });
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getAddressAction = createAsyncThunk(
  'customer/getAddress',
  async (token) => {
    try {
      const { data } = await axios.get(`${url}/address/get`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return data;
    } catch (error) {
      let message = error.response.data.message;

      return { message };
    }
  }
);

export const createAddressAction = createAsyncThunk(
  'customer/createAdress',
  async (addressData) => {
    try {
      console.log(addressData);
      const { data } = await axios.post(`${url}/address`, addressData.data, {
        headers: { Authorization: `Bearer ${addressData.token}` },
      });
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const updateAddressAction = createAsyncThunk(
  'customer/update',
  async (addressData) => {
    try {
      const { data } = await axios.put(`${url}/address`, addressData.data, {
        headers: { Authorization: `Bearer ${addressData.token}` },
      });

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const createCustomerProfileActions = createAsyncThunk(
  'customer/create',
  async (profileData) => {
    try {
      const { data } = await axios.post(
        `${url}/createProfile`,
        profileData.user,
        {
          headers: { Authorization: `Bearer ${profileData.token}` },
        }
      );
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getCustomerProfileAction = createAsyncThunk(
  'customer/get',
  async (token) => {
    try {
      const { data } = await axios.get(`${url}/getCustomerProfile`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getCustomerProfileFirstAction = createAsyncThunk(
  'customer/getprofile',
  async (userData) => {
    try {
      console.log('userData: ', userData);
      const { data } = await axios.post(
        `${url}/getCustomerProfileFirst`,
        userData
      );
      console.log(data);
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const getUserSubscriptionActions = createAsyncThunk(
  'customer/subscription',
  async (token) => {
    try {
      const { data } = await axios.get(`${url}/getUserSubscription`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const getUsersOrderActions = createAsyncThunk(
  'customer/orders',
  async (token) => {
    try {
      const { data } = await axios.get(`${url}/getOrders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return data;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const customerQuizDetailsAction = createAsyncThunk(
  'customer/quizzDetails',
  async (reqData) => {
    try {
      console.log('sent request don wory');
      const { data } = await axios.post(`${url}/quizdetail`, reqData.quizData, {
        headers: {
          Authorization: `Bearer ${reqData.token}`,
        },
      });
      return data.quizDetails;
    } catch (error) {
      console.log(error.message, 'during fetching the details');
    }
  }
);

export const lastQuizDetailsAction = createAsyncThunk(
  'customer/lastquizzDetails',
  async (reqData) => {
    try {
      console.log(reqData);
      const { data } = await axios.post(
        `${url}/lastquizdetail`,
        { lang: reqData.lang },
        {
          headers: {
            Authorization: `Bearer ${reqData.token}`,
          },
        }
      );
      return data.quizDetails;
    } catch (error) {
      console.log(error.message, 'during fetching the details');
    }
  }
);

export const getOrderDetails = createAsyncThunk(
  'order/orderDetail',
  async (orderData) => {
    try {
      const id = orderData?.id;
      console.log(id, 'for request ');
      const { data } = await axios.post(`${url}/orderDetail/${id}`);
      console.log('recieved data', data);

      return data?.orderDetails;
    } catch (error) {
      console.log(error.message, 'during fetching the details');
    }
  }
);

export const getPaymentGateWayInfo = createAsyncThunk(
  'payemnt/orderInfo',
  async () => {
    try {
      const { data } = await axios.get(`${url}/getGateWays`);
      console.log(
        data.gatwayInfo,
        'what............................................................'
      );
      return data.gatwayInfo;
    } catch (error) {
      console.log('error during getting gateways', error.message);
    }
  }
);

export const getUserShiipingInfo = createAsyncThunk(
  'user/shipping',
  async (emailData) => {
    try {
      const { data } = await axios.post(
        `${url}/getUserShiipingInfo`,
        emailData
      );
      console.log(data);
      return data.shippingAddress;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const getShippingAddress = createAsyncThunk(
  'user/shippingAddress',
  async (emailData) => {
    try {
      const token = localStorage.getItem("customer_token")
      console.log('EMAAIL => ', emailData)

      const { data } = await axios.post(
        `${url}/getShippingAddress`,
        emailData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("shippingAddress", data);
      return data.shippingAddress;
    } catch (error) {
      console.log(error.message);
    }
  }
);

export const updateShippingAddress = createAsyncThunk(
  'customer/updateShippingAddress',
  async (addressData) => {
    try {
      const { data } = await axios.put(`${url}/updateShippingAdrees`, addressData.data, {
        headers: { Authorization: `Bearer ${addressData.token}` },
      });

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const createNewUserPassAction = createAsyncThunk(
  'user/pass',
  async (userData) => {
    try {
      console.log(userData.token);
      const { data } = await axios.put(
        `${url}/createNewUserPass`,
        { password: userData.password },
        {
          headers: { Authorization: `Bearer ${userData.token}` },
        }
      );
      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);

export const logOutUserAction = createAsyncThunk(
  'user/logout',
  async (token) => {
    try {
      const { data } = await axios.get(`${url}/logout`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');
      const i18nextLng = localStorage.getItem('i18nextLng');
      localStorage.clear();
      localStorage.setItem('cookiesAccepted', cookiesAccepted);
      localStorage.setItem('i18nextLng', i18nextLng);

      return data;
    } catch (error) {
      let message = error.response.data.message;
      return { message };
    }
  }
);
